import { classNames, getLinkProps } from '~/lib';
import { Button } from '../Button';
import { Heading } from '../Heading';
import { RichText } from '../Molecules';
import { NewsletterBackground } from './NewsletterBanner';

interface BannerSimpleProps {
  content: any;
  className?: string;
}

export const BannerSimple = ({ content, className }: BannerSimpleProps) => {
  return (
    <div
      className={classNames(
        'w-full rounded-3xl py-10 px-6 md:p-12 text-grey-900 relative overflow-hidden z-10 flex flex-col justify-center items-center',
        content?.background_color === 'black'
          ? 'bg-black'
          : content?.background_color === 'brand'
          ? 'bg-brand'
          : 'bg-grey-50',
        content?.layout === 'double'
          ? 'md:justify-start'
          : 'md:justify-between',
        content?.layout === 'centered'
          ? 'md:flex-col'
          : 'md:flex-row md:items-start',
        className,
      )}
    >
      <div
        className={classNames(
          'relative z-10',
          content?.layout === 'double'
            ? 'md:mr-20'
            : content?.layout === 'centered' && 'max-w-2xl',
        )}
      >
        <Heading
          as="h2"
          variant={content?.layout === 'centered' ? 'h2' : 'h4'}
          className={classNames(
            'text-center',
            content?.background_color === 'black' && 'text-white',
            content?.layout === 'centered'
              ? 'text-center'
              : content?.layout === 'double'
              ? '!text-5xl !font-bold !leading-tight md:max-w-xs md:text-left'
              : '!text-3xl !font-normal md:text-left',
          )}
        >
          {content?.title}
        </Heading>
        {content?.subtext && content?.layout !== 'double' && (
          <RichText
            text={content?.subtext}
            className={classNames(
              't-2 text-center',
              content?.background_color === 'black'
                ? '!text-white'
                : content?.background_color === 'brand'
                ? 'text-grey-700'
                : 'text-grey-600',
              content?.layout === 'centered'
                ? 'mt-4 max-w-sm mx-auto'
                : 'md:text-left',
            )}
          />
        )}
      </div>
      <div className="md:max-w-sm relative z-10">
        {content?.subtext && content?.layout === 'double' && (
          <RichText
            text={content?.subtext}
            className={classNames(
              'mt-8 md:mt-0 text-center md:text-left',
              content?.background_color === 'black'
                ? '!text-white'
                : content?.background_color === 'brand'
                ? 'text-grey-700'
                : 'text-grey-600',
            )}
          />
        )}
        <Button
          variant={
            content?.background_color === 'brand'
              ? 'secondary'
              : content?.layout === 'centered'
              ? 'primary'
              : 'outline'
          }
          href={getLinkProps(content?.button_link)}
          className={classNames(
            'mt-10 w-full md:w-max',
            content?.layout === 'double'
              ? 'md:mt-8'
              : content?.layout === 'centered'
              ? 'w-full max-w-sm mt-10'
              : 'md:mt-0',
          )}
        >
          {content?.button_label}
        </Button>
      </div>
      {content?.background_graphic && (
        <div className="absolute w-full h-full left-0 right-0 top-0 bottom-0 pt-0 opacity-[0.08] z-0">
          <NewsletterBackground />
        </div>
      )}
    </div>
  );
};
