export const BannerGraphic = ({ className }: { className?: string }) => {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 449 210"
      className={className}
    >
      <path
        opacity=".16"
        d="m89.934 46.137 265.803.666-4.997 125.24H96.595L89.934 46.137Z"
        fill="url(#a)"
        fillOpacity=".5"
      />
      <path
        opacity=".16"
        d="M96.595 172.043 89.933 46.137 0 140.067l11.325 157.217 85.27-125.241Z"
        fill="url(#b)"
      />
      <path
        opacity=".16"
        d="m350.407 172.043 5.33-125.24 93.264 93.264-11.325 157.217-87.269-125.241Z"
        fill="url(#c)"
      />
      <ellipse cx="97.593" cy="11.496" rx="5.662" ry="2.665" fill="#fff" />
      <ellipse cx="112.915" cy="11.496" rx="5.662" ry="2.665" fill="#fff" />
      <ellipse cx="128.237" cy="11.496" rx="5.662" ry="2.665" fill="#fff" />
      <path
        d="m140.562 50.134.001 177.202"
        stroke="url(#d)"
        strokeWidth="5.329"
        strokeLinecap="round"
      />
      <path
        d="M195.855 68.787v179.866"
        stroke="url(#e)"
        strokeWidth="5.329"
        strokeLinecap="round"
      />
      <path
        d="M85.27 74.116v179.866"
        stroke="url(#f)"
        strokeWidth="5.329"
        strokeLinecap="round"
      />
      <path
        d="M361.732 54.13v173.206"
        stroke="url(#g)"
        strokeWidth="5.329"
        strokeLinecap="round"
      />
      <path
        d="M306.44 65.456v188.527"
        stroke="url(#h)"
        strokeWidth="5.329"
        strokeLinecap="round"
      />
      <path
        d="M251.147 51.466v159.881"
        stroke="url(#i)"
        strokeWidth="5.329"
        strokeLinecap="round"
      />
      <path
        opacity=".16"
        d="M0 140.067h449.001L437.01 297.284H10.659L0 140.067Z"
        fill="url(#j)"
      />
      <ellipse cx="140.562" cy="45.803" rx="23.982" ry="7.994" fill="#FBCD2A" />
      <ellipse cx="85.27" cy="73.116" rx="15.988" ry="5.329" fill="#FBCD2A" />
      <ellipse cx="195.855" cy="67.786" rx="15.988" ry="5.329" fill="#FBCD2A" />
      <ellipse cx="251.148" cy="49.134" rx="23.982" ry="7.994" fill="#FBCD2A" />
      <ellipse cx="306.439" cy="67.786" rx="15.988" ry="5.329" fill="#FBCD2A" />
      <path
        d="M86.03 6.92a14.656 14.656 0 0 1 10.802-4.75h253.342c4.049 0 7.917 1.674 10.687 4.626l81.903 87.27c1.198 1.276.293 3.366-1.457 3.366H7.574c-1.74 0-2.649-2.067-1.473-3.35L86.03 6.92Z"
        fill="#FBCC26"
        fillOpacity=".24"
        stroke="#fff"
        strokeWidth="2.665"
      />
      <ellipse cx="361.732" cy="48.467" rx="19.985" ry="6.662" fill="#FBCD2A" />
      <path
        stroke="#fff"
        strokeWidth="2.665"
        strokeLinecap="round"
        d="M349.741 11.495H247.15"
      />
      <path d="M72.613 20.822H374.39" stroke="#fff" strokeWidth="2.665" />
      <defs>
        <linearGradient
          id="a"
          x1="222.502"
          y1="47.469"
          x2="222.502"
          y2="172.043"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset=".578" stopColor="#fff" stopOpacity=".75" />
          <stop offset="1" stopColor="#fff" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="b"
          x1="27.5"
          y1="83.501"
          x2="88.5"
          y2="176.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset=".956" stopColor="#fff" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="c"
          x1="473.5"
          y1="63.5"
          x2="384"
          y2="215"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset=".956" stopColor="#fff" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="d"
          x1="141.062"
          y1="50.134"
          x2="141.063"
          y2="227.336"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBCC26" />
          <stop offset="1" stopColor="#FBCC26" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="e"
          x1="196.355"
          y1="68.787"
          x2="196.355"
          y2="248.653"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBCC26" />
          <stop offset="1" stopColor="#FBCC26" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="f"
          x1="85.77"
          y1="74.116"
          x2="85.77"
          y2="253.982"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBCC26" />
          <stop offset="1" stopColor="#FBCC26" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="g"
          x1="362.232"
          y1="54.131"
          x2="362.232"
          y2="227.336"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBCC26" />
          <stop offset="1" stopColor="#FBCC26" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="h"
          x1="306.94"
          y1="65.456"
          x2="306.94"
          y2="253.983"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBCC26" />
          <stop offset="1" stopColor="#FBCC26" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="i"
          x1="251.647"
          y1="51.466"
          x2="251.647"
          y2="211.347"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBCC26" />
          <stop offset="1" stopColor="#FBCC26" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="j"
          x1="224.5"
          y1="140.067"
          x2="224.5"
          y2="297.284"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset="1" stopColor="#fff" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};
