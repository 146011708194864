import { classNames, getLinkProps } from '~/lib';
import { Button } from '../Button';
import { Heading } from '../Heading';
import { Link } from '../Link';
import { BannerBg, BannerGraphic, MobileBannerBg } from '../Vectors';

interface GuideBannerProps {
  content?: any;
  className?: string;
}

export const GuideBanner = ({ content, className }: GuideBannerProps) => {
  const { kicker, title, button, button_link } = content;
  return (
    <Link
      href={getLinkProps(button_link)}
      className={classNames(
        'w-full bg-dark-grey-700 rounded-3xl py-12 px-4 md:p-12 text-white relative overflow-hidden z-10 flex flex-col items-center lg:items-start shadow-dark',
        className,
      )}
    >
      <div className="hidden lg:block absolute top-0 left-0 w-full h-full z-0">
        <BannerBg />
      </div>
      <div className="lg:hidden absolute top-0 left-0 w-full z-0">
        <MobileBannerBg className="w-full h-full" />
      </div>
      <div className="md:max-w-sm z-10 text-center lg:text-left flex flex-col items-center lg:items-start">
        <Heading as="h6" variant="h6" className="text-brand">
          {kicker}
        </Heading>
        <Heading as="h4" variant="h3" className="text-white mt-3">
          {title}
        </Heading>
        <Button variant="primary" className="mt-5">
          {button}
        </Button>
      </div>
      <div className="lg:absolute relative -bottom-12 lg:bottom-0 lg:right-28 w-full max-w-md lg:self-end">
        <BannerGraphic />
      </div>
    </Link>
  );
};
