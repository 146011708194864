export const MobileBannerBg = ({ className }: { className?: string }) => {
  return (
    <svg
      width="343"
      height="504"
      viewBox="0 0 343 504"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M105.389 368.143C120.444 332.668 128.601 252.736 40.7852 216.81C-42.4084 182.775 -103.715 232.578 -139.711 100.059C-183.566 -61.3973 -351.565 12.0456 -426.423 18.4941"
        stroke="url(#paint0_linear_3_451)"
        strokeWidth="8"
        strokeLinecap="round"
      />
      <g opacity="0.05" clipPath="url(#clip0_3_451)">
        <path
          d="M-648.728 -771.413C-254.516 -719.717 -150.259 -498.785 -58.4207 -303.845C-15.3895 -212.296 25.6201 -125.945 91.7553 -62.1198C166.266 9.79139 262.436 43.0033 394.707 43.0033C748.775 43.0033 931.297 -161.178 1107.75 -358.429C1266.59 -536.041 1416.48 -703.833 1678.71 -703.833V-706.721C1415.33 -706.721 1264.86 -538.64 1105.73 -360.45C929.564 -163.489 747.62 40.1153 394.995 40.1153C107.351 40.1153 31.6849 -119.88 -55.8215 -305.289C-102.896 -405.214 -151.703 -508.605 -238.921 -594.667C-337.979 -692.57 -471.982 -751.197 -648.439 -774.589C-741.144 -786.719 -816.232 -769.102 -903.45 -748.597C-986.046 -728.959 -1079.91 -706.721 -1209 -706.721V-703.833C-1079.62 -703.833 -985.469 -726.071 -902.583 -745.421C-815.654 -765.925 -740.855 -783.542 -648.728 -771.413Z"
          fill="white"
        />
        <path
          d="M-644.685 -733.291C-270.4 -687.372 -166.432 -469.906 -65.9296 -259.659C-18.8552 -161.467 25.3312 -68.4736 96.0872 0.838347C175.507 78.8143 276.587 115.203 413.478 115.203C772.168 115.203 947.47 -91.0001 1117.28 -290.561C1269.48 -469.328 1413.59 -638.854 1679 -638.854V-641.742C1412.44 -641.742 1267.75 -471.927 1114.97 -292.005C945.737 -93.0217 770.724 112.604 413.478 112.604C115.148 112.604 28.508 -68.7624 -63.3304 -260.814C-112.715 -364.205 -163.833 -471.061 -249.606 -558.278C-346.932 -657.337 -476.026 -715.386 -644.107 -735.89C-739.411 -747.442 -811.322 -724.627 -894.497 -698.058C-977.671 -671.777 -1071.82 -641.742 -1209 -641.742V-638.854C-1071.24 -638.854 -977.094 -668.889 -893.919 -695.458C-811.034 -722.028 -739.411 -744.843 -644.685 -733.291Z"
          fill="white"
        />
        <path
          d="M-640.93 -694.88C-286.284 -654.16 -178.851 -431.206 -74.5937 -215.761C25.6199 -8.11412 120.346 187.981 431.673 187.981C794.983 187.981 963.353 -20.5325 1126.24 -221.826C1272.66 -402.904 1410.7 -573.873 1679.29 -573.873V-576.761C1409.55 -576.761 1270.93 -405.214 1124.22 -223.559C961.909 -22.5541 793.828 185.382 431.962 185.382C122.368 185.382 28.2191 -10.1357 -71.7057 -216.916C-123.112 -323.484 -176.251 -433.516 -260.87 -521.889C-356.751 -621.814 -480.646 -679.285 -640.064 -697.48C-738.834 -708.743 -808.146 -680.44 -888.721 -647.806C-971.606 -614.594 -1064.6 -576.761 -1209 -576.761V-573.873C-1064.02 -573.873 -970.74 -611.995 -888.143 -645.496C-808.146 -677.841 -738.834 -705.855 -640.93 -694.88Z"
          fill="white"
        />
        <path
          d="M-636.887 -656.47C-301.59 -620.659 -190.98 -393.085 -83.8352 -172.73C-28.9632 -60.0983 22.732 45.8913 102.152 126.755C192.258 218.305 302.868 260.758 449.578 260.758C817.51 260.758 978.66 50.2233 1134.9 -153.381C1274.97 -336.191 1407.24 -508.894 1679 -508.894V-511.782C1406.08 -511.782 1273.24 -338.502 1132.88 -155.114C977.216 47.9129 816.355 257.87 449.867 257.87C129.01 257.87 27.064 48.2017 -81.236 -174.174C-188.67 -395.106 -299.858 -623.547 -636.598 -659.358C-739.122 -670.333 -806.702 -636.832 -884.966 -597.844C-966.119 -557.412 -1058.25 -511.782 -1209 -511.782V-508.894C-1057.67 -508.894 -965.253 -554.813 -883.811 -595.245C-805.835 -633.944 -738.545 -667.156 -636.887 -656.47Z"
          fill="white"
        />
        <path
          d="M-633.133 -618.06C-317.186 -586.58 -203.398 -354.963 -93.6543 -130.565C-37.6271 -15.9115 20.4217 102.496 104.751 189.136C199.189 286.173 317.886 333.536 468.062 333.536C634.699 333.536 773.035 290.216 890.287 200.688C994.255 121.557 1070.21 16.7229 1143.85 -84.9347C1277.86 -269.478 1404.35 -443.913 1678.71 -443.913V-446.801C1402.62 -446.801 1275.84 -271.788 1141.25 -86.6675C992.811 118.669 839.17 330.648 468.062 330.648C135.653 330.648 25.6201 105.962 -91.0551 -132.009C-201.088 -356.696 -315.164 -589.468 -632.844 -620.948C-739.411 -631.633 -808.434 -590.624 -881.79 -547.304C-961.787 -500.229 -1052.18 -446.801 -1209 -446.801V-443.913C-1051.32 -443.913 -960.632 -497.63 -880.346 -544.993C-804.391 -589.757 -738.545 -628.456 -633.133 -618.06Z"
          fill="white"
        />
        <path
          d="M-629.09 -579.649C-331.914 -552.213 -216.106 -317.13 -104.051 -89.5556C-43.9808 32.0291 17.8224 157.946 107.062 250.939C206.986 355.196 331.17 406.025 486.545 406.025C655.204 406.025 793.25 362.128 908.482 271.733C1010.43 191.736 1082.92 85.746 1153.1 -16.778C1280.74 -203.054 1401.46 -379.222 1679 -379.222V-382.11C1399.73 -382.11 1278.72 -205.364 1150.5 -18.5108C1008.7 189.136 861.696 403.137 486.256 403.137C141.718 403.137 18.1112 151.881 -101.452 -90.9996C-157.768 -205.076 -215.817 -322.906 -295.814 -413.589C-385.92 -515.247 -494.798 -570.408 -628.801 -582.826C-739.411 -592.934 -807.279 -546.437 -878.902 -497.052C-957.455 -442.758 -1046.41 -381.532 -1209 -381.532V-378.644C-1045.54 -378.644 -956.3 -440.159 -877.458 -494.164C-803.236 -545.571 -738.834 -589.757 -629.09 -579.649Z"
          fill="white"
        />
        <path
          d="M-625.335 -541.239C-346.643 -517.847 -234.3 -290.272 -115.026 -49.413C13.4903 210.218 146.05 478.802 504.739 478.802C675.709 478.802 813.466 434.327 926.387 343.355C1026.31 262.491 1095.33 155.635 1162.34 52.2447C1283.92 -135.764 1398.57 -313.376 1679.29 -313.376V-316.264C1396.84 -316.264 1281.61 -138.075 1159.74 50.8007C1024.58 259.892 884.8 476.203 504.739 476.203C148.071 476.203 15.5119 208.485 -112.426 -50.2794C-169.609 -166.088 -229.102 -286.229 -307.366 -376.912C-395.162 -478.57 -499.13 -533.153 -624.758 -543.839C-739.7 -553.658 -806.124 -501.385 -876.302 -445.935C-953.701 -385.576 -1040.92 -316.553 -1209 -316.553V-313.665C-1039.76 -313.665 -952.257 -382.688 -874.858 -443.914C-805.258 -498.786 -739.411 -550.77 -625.335 -541.239Z"
          fill="white"
        />
        <path
          d="M-621.581 -502.829C-361.083 -482.901 -247.585 -253.305 -127.155 -10.4245C9.73603 265.957 151.248 551.58 522.645 551.58C695.636 551.58 833.394 506.816 943.715 414.689C1041.62 333.247 1107.18 224.947 1170.71 120.691C1285.94 -69.0509 1394.82 -248.396 1679 -248.396V-251.284C1393.38 -251.284 1284.21 -71.3613 1168.4 119.247C1040.17 330.359 907.326 548.981 522.934 548.981C153.558 548.981 12.3352 264.224 -124.267 -11.2909C-244.986 -255.038 -359.062 -485.212 -621.003 -505.428C-740.566 -514.669 -805.546 -456.62 -874.281 -395.106C-949.946 -328.104 -1035.43 -251.573 -1209 -251.573V-248.685C-1034.28 -248.685 -948.214 -325.505 -872.548 -393.085C-804.391 -454.31 -739.7 -512.07 -621.581 -502.829Z"
          fill="white"
        />
        <path
          d="M-870.526 -342.545C-803.525 -409.546 -740.278 -473.082 -617.538 -464.707C-374.946 -447.957 -260.581 -217.205 -139.285 27.1194C-67.6625 171.231 6.27027 320.54 110.238 433.461C228.069 561.399 369.003 623.78 541.128 623.78C716.141 623.78 853.609 578.439 961.909 485.445C1057.79 403.137 1119.88 293.971 1179.95 188.559C1289.12 -2.91581 1392.22 -183.993 1679.29 -183.993V-186.881C1390.49 -186.881 1287.1 -5.22619 1177.64 187.115C1056.06 400.249 930.719 620.892 541.417 620.892C158.757 620.892 8.58069 318.23 -136.397 25.6754C-257.982 -219.516 -372.924 -450.845 -616.96 -467.884C-741.144 -476.548 -804.68 -412.723 -871.97 -345.144C-946.192 -270.345 -1029.94 -186.593 -1209 -186.593V-183.705C-1028.79 -183.705 -944.748 -268.034 -870.526 -342.545Z"
          fill="white"
        />
        <path
          d="M-868.505 -291.427C-802.947 -364.782 -740.855 -433.806 -613.783 -426.008C-388.808 -412.146 -274.155 -180.817 -152.57 64.0856C-77.1929 215.994 0.783065 373.39 110.238 493.82C234.422 630.422 381.421 696.846 559.322 696.846C736.357 696.846 873.826 651.216 979.815 557.356C1073.39 474.181 1132.3 364.149 1188.91 257.582C1291.72 64.3744 1389.04 -118.436 1679.29 -118.436V-121.324C1387.31 -121.324 1289.7 62.064 1186.6 256.138C1071.94 471.582 953.534 694.247 559.611 694.247C163.378 694.247 -2.97133 358.95 -149.682 63.2192C-271.555 -182.55 -386.786 -414.456 -613.206 -428.607C-741.722 -436.405 -804.391 -366.804 -870.238 -293.16C-946.481 -208.83 -1024.75 -121.613 -1209 -121.613V-118.725C-1023.3 -118.725 -940.994 -210.563 -868.505 -291.427Z"
          fill="white"
        />
        <path
          d="M-866.772 -240.309C-802.37 -319.729 -741.722 -394.529 -610.029 -387.597C-515.302 -382.688 -434.727 -333.592 -357.04 -233.667C-287.439 -144.428 -228.813 -26.0199 -166.721 99.6081C-11.0575 413.822 165.111 769.624 577.228 769.624C756.284 769.624 893.753 723.416 997.143 628.69C1088.69 544.649 1143.85 433.461 1197.28 326.027C1294.03 130.799 1385.29 -53.4559 1678.71 -53.4559V-56.3439C1383.56 -56.3439 1292.01 128.777 1194.68 324.583C1086.96 542.05 975.483 766.736 577.228 766.736C167.132 766.736 -8.74713 411.512 -164.122 98.1641C-226.214 -27.4639 -285.129 -146.161 -354.73 -235.689C-433.283 -336.191 -514.147 -385.576 -610.029 -390.485C-743.166 -397.417 -804.391 -322.04 -869.082 -242.042C-942.726 -151.07 -1019.26 -56.6327 -1209 -56.6327V-53.7447C-1017.81 -53.7447 -937.528 -152.803 -866.772 -240.309Z"
          fill="white"
        />
        <path
          d="M-864.75 -189.192C-801.791 -274.677 -742.298 -355.252 -605.985 -349.187C-415.088 -340.812 -306.499 -121.035 -180.871 133.398C-16.8329 465.518 169.443 842.113 595.712 842.113C776.79 842.113 913.969 795.616 1015.34 699.735C1104.87 615.116 1156.56 502.773 1206.52 394.184C1297.21 197.223 1382.69 11.5242 1679 11.5242V8.6362C1380.96 8.6362 1294.9 195.49 1203.92 393.029C1102.84 612.517 998.588 839.225 595.712 839.225C171.176 839.225 -14.5225 463.496 -178.272 131.954C-240.364 6.32585 -298.991 -112.371 -366.281 -201.033C-441.658 -300.38 -517.901 -348.321 -605.985 -352.364C-743.742 -358.429 -803.813 -277.276 -867.349 -190.925C-939.549 -93.0214 -1014.35 8.34738 -1209.29 8.34738V11.2354C-1121.49 11.2354 -1049.87 -9.84697 -990.089 -52.8782C-937.238 -90.9998 -900.561 -140.962 -864.75 -189.192Z"
          fill="white"
        />
        <path
          d="M-863.018 -137.785C-801.214 -229.335 -742.877 -315.975 -602.231 -311.065C-427.796 -305 -320.651 -86.9564 -196.178 165.455C-113.293 333.825 -19.4328 524.722 106.773 669.122C251.173 834.316 417.233 914.602 613.906 914.602C797.005 914.602 934.185 867.528 1033.24 771.068C1120.75 685.584 1168.98 572.085 1215.48 462.63C1299.81 263.936 1379.51 76.5044 1679 76.5044V73.6164C1377.78 73.6164 1297.5 261.914 1212.88 461.475C1118.73 682.984 1021.4 912.003 614.194 912.003C174.641 912.003 -20.8768 514.903 -193.29 164.588C-318.052 -88.6892 -425.486 -307.311 -601.654 -313.664C-744.321 -318.574 -802.947 -231.356 -865.039 -139.229C-936.084 -34.6836 -1008.86 73.6164 -1209 73.6164V76.5044C-1118.89 76.5044 -1046.12 53.978 -986.335 8.05876C-933.774 -32.662 -897.962 -86.09 -863.018 -137.785Z"
          fill="white"
        />
        <path
          d="M-861.285 -86.0903C-800.926 -183.705 -743.743 -276.121 -598.188 -272.366C-439.926 -268.612 -334.225 -52.8783 -212.062 196.645C-125.711 373.102 -27.8079 573.24 104.174 726.015C255.794 901.895 428.496 987.379 632.1 987.379C817.221 987.379 954.401 940.016 1050.86 842.402C1136.35 756.051 1181.11 641.686 1224.14 531.075C1302.41 330.648 1376.34 141.484 1678.71 141.484V138.596C1374.32 138.596 1300.09 328.626 1221.25 529.92C1134.03 753.451 1043.64 984.491 631.811 984.491C176.374 984.491 -37.9159 546.959 -210.041 195.201C-332.781 -55.1887 -438.482 -271.5 -598.766 -275.254C-746.054 -279.009 -803.525 -186.015 -864.462 -87.5343C-932.618 23.6537 -1003.66 138.596 -1209 138.596V141.484C-1116.3 141.484 -1042.36 117.802 -982.87 68.9953C-930.308 25.6753 -895.363 -31.2183 -861.285 -86.0903Z"
          fill="white"
        />
        <path
          d="M-859.552 -34.3949C-800.926 -137.208 -745.476 -234.245 -598.766 -234.245C-597.322 -234.245 -595.878 -234.245 -594.434 -234.245C-451.766 -232.512 -348.376 -20.2437 -229.102 225.525C-48.3127 597.211 176.951 1060.16 650.295 1060.16C837.726 1060.16 974.328 1012.22 1068.77 913.736C1152.23 826.518 1193.24 710.998 1233.09 599.521C1305 397.361 1373.16 206.464 1678.71 206.464V203.576C1371.14 203.576 1302.69 395.34 1230.2 598.366C1149.92 823.919 1066.74 1056.98 650.006 1056.98C178.395 1056.98 -46.2911 595.189 -226.791 224.081C-286.573 100.763 -343.178 -15.3341 -401.804 -99.0861C-467.073 -192.369 -528.298 -236.266 -594.722 -237.133C-746.631 -239.154 -802.947 -140.385 -862.44 -35.8389C-929.153 81.9915 -998.465 203.576 -1209 203.576V206.464C-1113.98 206.464 -1038.9 181.339 -979.404 129.643C-927.131 84.0131 -892.764 23.9427 -859.552 -34.3949Z"
          fill="white"
        />
        <path
          d="M-610.318 507.105C-471.694 515.769 -352.708 652.371 -214.662 810.345C-128.31 909.403 -30.1184 1022.04 85.1128 1115.32C217.383 1222.46 352.253 1288.89 498.097 1318.34C556.434 1330.19 612.75 1335.96 669.933 1335.96C673.11 1335.96 675.998 1335.96 679.174 1335.96C1058.08 1331.05 1146.16 1175.1 1231.36 1024.63C1309.62 886.299 1383.56 755.762 1679 755.762V752.874C1381.82 752.874 1307.6 884.278 1229.05 1023.19C1144.14 1173.08 1056.35 1328.16 679.174 1333.07C619.104 1333.65 559.9 1327.87 498.674 1315.46C172.33 1249.9 -32.14 1015.39 -212.64 808.612C-350.975 650.061 -470.25 513.17 -610.318 504.506C-737.39 496.708 -805.258 554.468 -877.169 615.405C-956.3 682.984 -1038.32 752.874 -1209 752.874V755.762C-1037.16 755.762 -954.856 685.583 -874.858 617.426C-803.525 556.778 -736.234 499.307 -610.318 507.105Z"
          fill="white"
        />
        <path
          d="M-607.718 419.31C-470.25 426.819 -353.286 572.951 -218.127 742.188C-132.642 849.044 -35.8944 970.34 79.048 1071.42C210.741 1187.52 346.477 1259.43 493.476 1291.49C552.391 1304.48 611.884 1310.84 670.51 1310.84C672.821 1310.84 675.42 1310.84 677.73 1310.84C1062.41 1306.5 1149.05 1140.73 1232.8 980.448C1309.34 834.027 1381.82 695.403 1679 695.403V692.515C1380.09 692.515 1307.31 831.716 1230.2 979.004C1146.74 1138.71 1060.39 1303.62 677.442 1307.95C616.794 1308.53 554.99 1301.88 493.765 1288.6C164.822 1216.98 -46.58 952.723 -216.106 740.455C-351.842 570.641 -469.094 423.931 -607.718 416.422C-738.545 409.202 -804.391 473.027 -874.57 540.317C-951.968 614.827 -1032.54 692.226 -1209 692.226V695.114C-1031.39 695.114 -950.524 617.427 -872.259 542.339C-802.947 475.337 -737.101 412.379 -607.718 419.31Z"
          fill="white"
        />
        <path
          d="M-869.949 467.251C-802.081 394.184 -738.256 325.45 -605.408 331.515C-469.094 337.868 -354.73 493.243 -221.882 673.454C-137.552 788.107 -41.6705 918.067 72.6943 1026.94C204.387 1152 340.412 1229.39 488.855 1264.05C548.926 1278.2 609.862 1285.42 670.51 1285.42C672.532 1285.42 674.554 1285.42 676.575 1285.42C1066.74 1281.67 1151.94 1105.79 1234.54 935.973C1309.62 781.176 1380.67 635.044 1679.29 635.044V632.156C1378.94 632.156 1307.6 779.155 1231.94 934.818C1149.92 1104.05 1065.01 1279.07 676.575 1282.82C614.483 1283.4 551.525 1276.18 489.722 1261.74C157.602 1184.05 -51.4897 900.451 -219.282 672.299C-352.419 491.51 -467.362 335.558 -604.83 329.204C-739.122 322.851 -803.525 392.451 -871.682 465.807C-947.925 546.959 -1026.77 631.867 -1209 631.867V634.755C-1025.32 634.755 -946.481 549.559 -869.949 467.251Z"
          fill="white"
        />
        <path
          d="M-867.638 392.162C-801.503 313.031 -739.411 238.232 -602.809 243.719C-467.939 248.918 -355.596 413.534 -225.636 604.43C-142.173 726.882 -47.7352 865.794 66.6296 982.181C198.034 1116.18 334.636 1199.36 484.523 1236.9C545.46 1252.21 608.13 1260.01 670.51 1260.01C671.954 1260.01 673.687 1260.01 675.42 1260.01C852.454 1258.56 982.703 1219.86 1073.96 1141.31C1154.54 1071.71 1196.13 980.159 1235.98 891.498C1309.34 728.037 1378.65 574.106 1679 574.106V571.218C1376.92 571.218 1307.03 726.015 1233.09 890.054C1152.52 1068.82 1069.05 1253.94 675.131 1257.12C611.306 1257.7 547.482 1249.9 485.101 1234.01C149.515 1150.26 -57.2656 846.734 -223.326 602.698C-353.863 411.223 -466.206 246.03 -602.52 240.831C-740.278 235.633 -803.236 310.721 -869.66 390.43C-944.17 479.091 -1020.99 571.218 -1209 571.218V574.106C-1019.55 574.106 -942.438 481.69 -867.638 392.162Z"
          fill="white"
        />
        <path
          d="M-865.617 317.074C-801.214 231.878 -740.566 151.303 -600.498 155.635C-466.784 159.678 -357.04 333.536 -229.679 534.83C-147.371 665.079 -53.8001 812.944 60.2759 937.417C191.391 1080.37 328.571 1169.61 480.191 1209.76C542.283 1226.22 606.108 1234.59 670.51 1234.59C671.665 1234.59 673.109 1234.59 674.265 1234.59C853.898 1233.44 985.302 1192.72 1076.56 1110.12C1157.43 1037.05 1198.15 940.305 1237.42 846.734C1309.34 675.475 1377.49 513.747 1679.29 513.747V510.859C1375.47 510.859 1307.03 673.454 1234.54 845.578C1155.12 1034.16 1073.39 1229.1 673.976 1231.7C608.707 1231.99 543.727 1223.62 480.769 1206.87C141.717 1116.76 -62.7529 793.306 -227.08 533.386C-354.73 331.515 -465.051 157.079 -599.921 153.036C-741.433 148.704 -802.658 229.857 -867.35 315.63C-940.416 411.512 -1015.5 510.57 -1209 510.57V513.458C-1014.06 513.458 -938.394 413.822 -865.617 317.074Z"
          fill="white"
        />
        <path
          d="M-863.595 242.275C-802.081 152.747 -743.743 67.8401 -605.986 67.8401C-603.386 67.8401 -600.787 67.8401 -598.188 67.8401C-466.206 70.7281 -358.773 253.539 -234.3 464.94C-71.4167 741.611 131.321 1085.86 474.993 1182.32C537.951 1199.94 603.22 1208.89 669.644 1208.89C670.511 1208.89 671.377 1208.89 672.243 1208.89C854.187 1208.02 987.035 1165.57 1078.58 1078.64C1159.45 1001.82 1199.3 900.162 1238 801.97C1308.47 622.625 1374.89 453.099 1678.71 453.099V450.211C1373.16 450.211 1306.16 620.603 1235.4 800.815C1157.43 999.22 1077.14 1203.98 672.243 1206C605.242 1206.29 539.395 1197.34 475.859 1179.43C133.343 1083.26 -69.1063 739.589 -231.701 463.207C-294.37 356.929 -353.286 256.715 -412.201 184.515C-477.758 104.229 -536.674 66.1073 -598.188 64.9521C-600.787 64.9521 -603.386 64.9521 -606.274 64.9521C-745.765 64.9521 -804.391 150.437 -866.483 240.543C-936.662 343.644 -1009.73 450.211 -1209 450.211V453.099C-1008.28 453.099 -934.64 345.955 -863.595 242.275Z"
          fill="white"
        />
        <path
          d="M-861.574 167.187C-801.214 70.7281 -744.032 -20.2438 -600.787 -20.2438C-599.054 -20.2438 -597.322 -20.2438 -595.589 -20.2438C-465.051 -18.2222 -359.928 172.963 -238.343 394.184C-76.9039 687.605 123.523 1052.07 470.95 1154.88C534.775 1173.94 601.487 1183.47 669.644 1183.47C670.222 1183.47 670.799 1183.47 671.377 1183.47C855.92 1182.9 989.923 1138.42 1081.47 1047.45C1162.63 966.875 1201.61 860.307 1239.45 757.206C1308.47 569.486 1373.45 392.451 1678.71 392.451V389.563C1371.43 389.563 1305.87 567.753 1236.56 756.339C1160.03 964.275 1081.18 1179.43 670.799 1180.59C670.222 1180.59 669.644 1180.59 669.067 1180.59C601.199 1180.59 534.775 1171.06 471.239 1152.28C125.545 1049.76 -74.5935 685.583 -235.455 393.029C-296.681 281.552 -354.441 176.429 -412.201 101.052C-476.314 17.3002 -534.652 -22.2654 -595.3 -23.1318C-597.033 -23.1318 -598.766 -23.1318 -600.498 -23.1318C-745.476 -23.1318 -802.947 68.7066 -863.884 165.743C-932.907 275.776 -1004.24 389.563 -1209 389.563V392.451C-1002.51 392.451 -930.886 278.087 -861.574 167.187Z"
          fill="white"
        />
        <path
          d="M-859.552 92.3882C-800.059 -11.291 -743.743 -109.194 -592.99 -108.039C-533.786 -107.461 -477.47 -67.0294 -415.089 19.3218C-358.484 97.5866 -302.168 207.331 -242.386 323.717C-82.9688 633.311 115.437 1018.57 466.329 1127.74C531.02 1147.95 599.177 1158.06 669.066 1158.06C669.355 1158.06 669.644 1158.06 669.933 1158.06C856.786 1157.77 992.234 1111.27 1084.07 1016.26C1165.22 931.93 1203.64 820.453 1240.89 712.731C1308.18 516.924 1372.01 332.092 1679 332.092V329.204C1369.98 329.204 1305.87 514.903 1238.29 711.864C1163.2 929.619 1085.8 1154.88 670.222 1155.46C669.933 1155.46 669.644 1155.46 669.355 1155.46C599.754 1155.46 531.598 1145.35 467.484 1125.14C117.747 1016.26 -80.3696 631.578 -239.21 322.562C-298.991 206.175 -355.596 96.4314 -412.201 17.8778C-475.159 -69.3398 -532.342 -110.061 -592.412 -110.638C-593.278 -110.638 -594.145 -110.638 -595.011 -110.638C-745.765 -110.638 -802.081 -12.4462 -861.574 91.233C-929.153 208.197 -998.754 329.204 -1209 329.204V332.092C-997.021 332.092 -927.131 210.219 -859.552 92.3882Z"
          fill="white"
        />
        <path
          d="M-857.53 21.6207C-799.482 -86.2252 -744.898 -188.111 -590.39 -188.111C-462.741 -188.111 -367.148 6.29524 -246.43 252.638C-153.147 442.788 -47.1576 658.196 96.9536 827.06C263.88 1022.6 450.733 1117.39 668.777 1117.39C858.23 1117.39 994.833 1070 1086.67 972.084C1168.11 885.523 1205.66 771.15 1242.05 660.466C1307.89 460.1 1370.27 270.802 1679 270.802V267.964C1368.25 267.964 1305.58 458.113 1239.45 659.331C1165.8 882.969 1089.85 1114.27 668.777 1114.27C180.128 1114.27 -54.9552 635.775 -243.83 251.219C-302.457 132.021 -357.618 19.3503 -413.067 -60.6827C-474.582 -149.798 -530.898 -191.233 -590.39 -191.233C-746.92 -191.233 -801.792 -88.4956 -860.13 20.2017C-925.398 141.954 -992.978 267.964 -1209 267.964V270.802C-991.245 270.802 -923.088 143.941 -857.53 21.6207Z"
          fill="white"
        />
      </g>
      <path
        d="M337.789 340.09C309.516 313.904 269.312 244.339 334.685 175.574C396.617 110.428 472.951 130.725 451.463 -4.90461C425.283 -170.149 608.625 -172.024 679.548 -196.826"
        stroke="url(#paint1_linear_3_451)"
        strokeWidth="8"
        strokeLinecap="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3_451"
          x1="-287.168"
          y1="-65.1021"
          x2="8.00824"
          y2="426.602"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.453125" stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3_451"
          x1="518.264"
          y1="-215.996"
          x2="450.576"
          y2="353.496"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.453125" stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <clipPath id="clip0_3_451">
          <rect
            width="2888"
            height="2888"
            fill="white"
            transform="translate(-1209 -1174)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
