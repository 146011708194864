import { useState } from 'react';
import { getCookies } from 'cookies-next';
import { useRouter } from 'next/router';
import { useForm } from 'react-hook-form';
import { defineMessages, useIntl } from 'react-intl';
import { Parallax } from 'react-scroll-parallax';

import { Button, Heading } from '~/components';
import { classNames, track } from '~/lib';

interface NewsletterBannerProps {
  className?: string;
  content?: {
    title: string;
    subtitle: string;
    disclaimer: string;
    button_label: string;
  };
}

const messages = defineMessages({
  placeholder: {
    id: 'newsletter.placeholder',
    defaultMessage: 'Enter your work email',
  },
});

export const NewsletterBanner = ({
  className,
  content,
}: NewsletterBannerProps) => {
  const { query, asPath } = useRouter();
  const pageName =
    asPath?.split('?')[0] === '/'
      ? 'Home'
      : asPath?.split('?')[0]?.split('/')[1];

  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const { formatMessage } = useIntl();

  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm();

  const onSubmit = async (input) => {
    setError('');
    setSuccess('');

    try {
      const cookies = getCookies();
      const hubspotutk = cookies?.hubspotutk || '';

      const utmQueries = {
        utm_source: query?.utm_source || '',
        utm_medium: query?.utm_medium || '',
        utm_campaign: query?.utm_campaign || '',
        utm_content: query?.utm_content || '',
        utm_term: query?.utm_term || '',
      };

      const res = await fetch(`/api/newsletter`, {
        method: 'POST',
        body: JSON.stringify({
          ...input,
          pageName,
          hutk: hubspotutk,
        }),
        headers: { 'Content-Type': 'application/json' },
      });

      if (res.status !== 200) {
        setError((await res?.json())?.message);
      } else {
        setSuccess('Subscribed successfully!');
        track({
          event: 'new_subscriber',
        });
      }
    } catch (e) {
      setError(e?.message);
    }
  };

  return (
    <div
      className={classNames(
        'relative flex rounded-3xl bg-[#FBCC26] overflow-hidden w-full shadow-xl justify-between lg:mx-auto flex-col lg:flex-row',
        className,
      )}
    >
      <div className="absolute w-full h-full left-0 pt-0 opacity-[0.15]">
        <NewsletterBackground />
      </div>
      <div className="max-w-xl w-full text-center mx-auto z-10 lg:text-left pb-0 pt-6 px-[14px] sm:p-12 flex-1 lg:mx-0">
        {!!content?.title && (
          <Heading as="h3" variant="h3" className="tracking-tight">
            {content.title}
          </Heading>
        )}
        {!!content?.subtitle && (
          <p className="mt-3 text-base max-w-md text-gray-700 sm:mt-4">
            {content.subtitle}
          </p>
        )}

        <form
          onSubmit={handleSubmit(onSubmit)}
          className="mt-8 flex sm:mt-12 drop-shadow-light border border-brand rounded"
        >
          <input
            className="absolute"
            aria-hidden="true"
            type="text"
            tabIndex={-1}
            style={{ left: '-5000px' }}
            {...register('b9127913821798nd1ee821u9ejo')}
          />
          <div className="min-w-0 flex-1">
            <label htmlFor="cta-email" className="sr-only">
              Email address
            </label>
            <input
              id="cta-email"
              type="email"
              className={classNames(
                !!success && 'bg-gray-50 text-gray-700',
                'block w-full rounded-l border-none px-5 py-3 text-base text-gray-900 font-normal placeholder-grey-300 focus:outline-none focus:border-transparent focus:ring-0 focus:ring-yellow-300',
              )}
              placeholder={formatMessage(messages.placeholder)}
              required
              {...register('email', { required: true })}
              defaultValue=""
              disabled={!!success}
            />
          </div>
          <div>
            <Button
              type="submit"
              variant="dark-border"
              size="medium"
              className="w-full h-full rounded-l-none border-none px-[14px] lg:px-8"
              isLoading={isSubmitting}
              disabled={!!success}
            >
              {success ? 'Subscribed!' : content?.button_label || 'Notify me'}
            </Button>
          </div>
        </form>

        {(!!content?.disclaimer || error || success) && (
          <p
            className={classNames(
              error
                ? 'text-red-700 text-sm'
                : success
                ? 'text-gray-700 text-sm'
                : 'text-gray-700 text-xs',
              'mt-4 h-3',
            )}
          >
            {success || error || content?.disclaimer}
          </p>
        )}
      </div>
      <div className="flex justify-center items-end relative lg:mr-14">
        <svg
          width="380"
          height="306"
          viewBox="0 0 380 306"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.8"
            d="M32.5945 144.495V309.052C32.5945 328.354 48.2108 344 67.4745 344H311.634C330.899 344 346.515 328.354 346.515 309.052V144.495C346.515 132.809 340.686 121.897 330.982 115.415L208.902 33.8696C197.186 26.0435 181.923 26.0435 170.207 33.8696L48.1265 115.415C38.4229 121.897 32.5945 132.809 32.5945 144.495Z"
            fill="white"
          />
          <g filter="url(#filter0_f_63_33365)">
            <ellipse
              cx="189.952"
              cy="190.766"
              rx="69.9463"
              ry="69.8995"
              fill="#FBCC26"
            />
          </g>
          <defs>
            <filter
              id="filter0_f_63_33365"
              x="0.00585938"
              y="0.866455"
              width="379.893"
              height="379.799"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feGaussianBlur
                stdDeviation="60"
                result="effect1_foregroundBlur_63_33365"
              />
            </filter>
          </defs>
        </svg>
        <div className="absolute bottom-0 left-2/4 -translate-x-2/4 z-10">
          <Parallax y={[0, -30]}>
            <svg
              width="264"
              height="178"
              viewBox="0 0 264 178"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g filter="url(#filter0_ddd_63_33365)">
                <rect
                  x="20"
                  y="10"
                  width="224"
                  height="186"
                  rx="8"
                  fill="white"
                />
              </g>
              <rect
                opacity="0.4"
                x="88"
                y="65.0839"
                width="87"
                height="8.28741"
                fill="#FBCC26"
              />
              <rect
                opacity="0.24"
                x="72"
                y="81.6587"
                width="119"
                height="3.10777"
                fill="#FBCC26"
              />
              <rect
                opacity="0.24"
                x="72"
                y="89.946"
                width="119"
                height="3.10779"
                fill="#FBCC26"
              />
              <rect
                opacity="0.24"
                x="72"
                y="98.2335"
                width="119"
                height="3.10776"
                fill="#FBCC26"
              />
              <ellipse
                cx="132"
                cy="36.0778"
                rx="16"
                ry="16.5749"
                fill="#F3F4F6"
              />
              <path
                d="M125.995 37.3991L135.323 25.3003L131.885 34.029L138.423 32.9402L127.691 46.1207L132.463 36.3038L125.995 37.3991Z"
                fill="#374151"
              />
              <rect
                opacity="0.24"
                x="62"
                y="68.1917"
                width="139"
                height="56.9761"
                fill="url(#paint0_linear_63_33365)"
              />
              <defs>
                <filter
                  id="filter0_ddd_63_33365"
                  x="0"
                  y="0"
                  width="264"
                  height="226"
                  filterUnits="userSpaceOnUse"
                  colorInterpolationFilters="sRGB"
                >
                  <feFlood floodOpacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset />
                  <feGaussianBlur stdDeviation="0.5" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_63_33365"
                  />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dy="2" />
                  <feGaussianBlur stdDeviation="3" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_63_33365"
                    result="effect2_dropShadow_63_33365"
                  />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dy="10" />
                  <feGaussianBlur stdDeviation="10" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.98375 0 0 0 0 0.800233 0 0 0 0 0.149583 0 0 0 0.16 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="effect2_dropShadow_63_33365"
                    result="effect3_dropShadow_63_33365"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect3_dropShadow_63_33365"
                    result="shape"
                  />
                </filter>
                <linearGradient
                  id="paint0_linear_63_33365"
                  x1="131.5"
                  y1="68.1917"
                  x2="131.5"
                  y2="125.168"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#FBCC26" stopOpacity="0" />
                  <stop offset="1" stopColor="#FBCC26" />
                </linearGradient>
              </defs>
            </svg>
          </Parallax>
        </div>
        <div className="absolute bottom-0 left-2/4 -translate-x-2/4 z-10">
          <svg
            width="315"
            height="159"
            viewBox="0 0 315 159"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.59448 167.275V3.67008L135.386 86.1906C148.961 94.6265 166.148 94.6265 179.723 86.1906L312.515 3.67008V167.275C312.515 179.426 302.665 189.275 290.515 189.275H157.555H24.5945C12.4442 189.275 2.59448 179.426 2.59448 167.275Z"
              fill="white"
              stroke="#FEF9E6"
              strokeWidth="4"
            />
            <path
              d="M102.5 64.2125L4 147.087"
              stroke="#FEF9E6"
              strokeWidth="4"
            />
            <path
              d="M212 61.6228L313 147.087"
              stroke="#FEF9E6"
              strokeWidth="4"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};

export const NewsletterBackground = ({ className }: { className?: string }) => (
  <svg
    viewBox="0 0 1112 314"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={classNames('w-full h-full', className)}
  >
    <path
      d="M1260.39 908.001C960.688 875.597 877.438 722.136 796.963 573.769C759.269 504.477 723.888 438.854 667.231 389.942C603.637 334.916 522.7 309.237 413.087 309.237C125.874 309.237 -14.4955 454.75 -150.471 595.576C-272.34 721.728 -387.734 841.358 -600.253 841.358V843.396C-386.809 843.396 -270.952 723.562 -148.621 596.595C-13.108 456.177 127.03 311.071 413.087 311.071C651.969 311.071 721.344 439.057 794.882 574.584C834.426 647.545 875.357 722.951 944.038 784.498C1021.97 854.402 1125.34 895.365 1259.93 909.835C1336.24 917.987 1393.82 901.887 1460.42 883.137C1527.02 864.592 1602.41 843.396 1712.25 843.396V841.358C1601.95 841.358 1526.56 862.554 1459.96 881.303C1393.59 900.053 1336.24 916.153 1260.39 908.001Z"
      fill="white"
    />
    <path
      d="M1257.38 880.896C973.407 852.16 887.382 694.826 803.901 542.792C723.657 396.259 647.806 257.879 398.518 257.879C107.605 257.879 -27.214 405.023 -157.639 547.071C-274.883 674.854 -385.421 795.503 -600.484 795.503V797.541C-384.496 797.541 -273.496 676.484 -156.021 548.294C-26.0579 406.449 108.53 259.713 398.287 259.713C646.188 259.713 721.575 397.686 801.588 543.607C842.751 618.809 885.301 696.457 953.057 758.819C1029.83 829.334 1129.04 869.89 1256.69 882.73C1335.78 890.678 1391.28 870.706 1455.8 847.676C1522.16 824.239 1596.63 797.541 1712.25 797.541V795.503C1596.17 795.503 1521.47 822.405 1455.33 846.046C1391.28 868.871 1335.78 888.64 1257.38 880.896Z"
      fill="white"
    />
    <path
      d="M1254.15 853.79C985.663 828.519 897.094 667.925 811.301 512.425C767.363 432.943 725.969 358.149 662.375 301.085C590.225 236.48 501.656 206.522 384.181 206.522C89.5674 206.522 -39.4705 355.092 -164.577 498.771C-276.734 627.776 -382.646 749.648 -600.253 749.648V751.686C-381.721 751.686 -275.346 629.407 -162.958 499.994C-38.3143 356.722 90.4923 208.56 383.949 208.56C640.869 208.56 722.5 356.518 809.219 513.444C895.244 669.351 984.276 830.557 1253.91 855.828C1336.01 863.573 1390.12 839.932 1452.79 812.419C1517.77 783.887 1591.54 751.686 1712.25 751.686V749.648C1591.08 749.648 1517.08 782.053 1451.86 810.585C1389.43 837.894 1335.55 861.331 1254.15 853.79Z"
      fill="white"
    />
    <path
      d="M1251.14 826.685C998.151 804.471 907.038 641.023 819.163 482.67C774.3 401.762 727.819 318.204 660.294 257.064C584.675 188.587 489.631 155.164 369.381 155.164C235.949 155.164 125.18 185.734 31.2921 248.912C-51.9581 304.753 -112.777 378.732 -171.746 450.47C-279.046 580.698 -380.334 703.793 -600.022 703.793V705.831C-378.946 705.831 -277.427 582.328 -169.665 451.693C-50.8018 306.791 72.2235 157.202 369.381 157.202C635.55 157.202 723.657 315.758 817.082 483.689C905.188 642.246 996.532 806.509 1250.91 828.723C1336.24 836.263 1391.51 807.324 1450.25 776.754C1514.3 743.534 1586.68 705.831 1712.25 705.831V703.793C1585.99 703.793 1513.38 741.7 1449.09 775.123C1388.27 806.712 1335.55 834.022 1251.14 826.685Z"
      fill="white"
    />
    <path
      d="M1247.9 799.579C1009.94 780.218 917.213 614.325 827.488 453.731C779.388 367.931 729.9 279.074 658.444 213.45C578.431 139.879 478.993 104.01 354.581 104.01C219.53 104.01 108.992 134.987 16.7234 198.777C-64.9081 255.229 -122.952 330.024 -179.146 402.373C-281.359 533.824 -378.021 658.142 -600.253 658.142V660.18C-376.634 660.18 -279.74 535.454 -177.065 403.596C-63.5206 257.064 54.186 106.048 354.812 106.048C630.694 106.048 729.669 283.354 825.407 454.75C870.501 535.251 916.982 618.401 981.038 682.394C1053.19 754.132 1140.37 793.058 1247.67 801.821C1336.24 808.954 1390.58 776.142 1447.93 741.292C1510.83 702.978 1582.06 659.772 1712.25 659.772V657.734C1581.37 657.734 1509.91 701.144 1446.78 739.254C1387.35 775.531 1335.78 806.712 1247.9 799.579Z"
      fill="white"
    />
    <path
      d="M1244.9 772.474C1021.74 755.966 931.782 595.372 836.276 425.403C733.369 242.187 627.225 52.6526 340.012 52.6526C203.111 52.6526 92.8049 84.0377 2.38597 148.235C-77.6267 205.299 -132.896 280.705 -186.546 353.665C-283.902 486.339 -375.709 611.676 -600.484 611.676V613.714C-374.321 613.714 -282.052 487.969 -184.464 354.684C-76.2392 207.133 35.686 54.4867 340.012 54.4867C625.606 54.4867 731.75 243.409 834.194 426.014C879.982 507.738 927.62 592.519 990.289 656.512C1060.59 728.25 1143.84 766.768 1244.43 774.308C1336.47 781.238 1389.66 744.35 1445.85 705.22C1507.83 662.626 1577.67 613.918 1712.25 613.918V611.88C1576.74 611.88 1506.67 660.588 1444.7 703.794C1388.96 742.516 1336.24 779.2 1244.9 772.474Z"
      fill="white"
    />
    <path
      d="M1241.89 745.369C1033.3 731.306 942.42 569.285 845.988 397.89C736.375 202.853 623.063 1.29464 325.674 1.29464C187.155 1.29464 76.8485 32.8837 -11.4892 97.8959C-89.8832 155.368 -142.377 231.793 -193.252 305.364C-285.521 439.261 -372.703 565.821 -600.253 565.821V567.859C-371.546 567.859 -284.134 440.891 -191.402 306.383C-88.7269 157.406 17.6483 3.12889 325.443 3.12889C621.212 3.12889 734.294 204.076 843.676 398.501C940.338 570.508 1031.68 732.937 1241.43 747.203C1337.16 753.724 1389.2 712.761 1444.23 669.351C1504.82 622.07 1573.27 568.063 1712.25 568.063V566.025C1572.35 566.025 1503.43 620.235 1442.85 667.925C1388.27 711.13 1336.47 751.89 1241.89 745.369Z"
      fill="white"
    />
    <path
      d="M1441.23 632.26C1387.58 679.541 1336.93 724.377 1238.65 718.467C1044.4 706.646 952.826 543.81 855.701 371.396C798.351 269.699 739.15 164.335 655.9 84.649C561.55 -5.63441 448.7 -49.6553 310.874 -49.6553C170.736 -49.6553 60.6611 -17.6586 -26.0578 47.965C-102.833 106.048 -152.552 183.084 -200.652 257.471C-288.065 392.591 -370.621 520.373 -600.484 520.373V522.411C-369.234 522.411 -286.446 394.221 -198.802 258.49C-101.446 108.086 -1.08269 -47.6173 310.643 -47.6173C617.05 -47.6173 737.3 165.965 853.388 372.415C950.745 545.441 1042.78 708.684 1238.19 720.709C1337.63 726.823 1388.5 681.783 1442.38 634.094C1501.81 581.309 1568.88 522.208 1712.25 522.208V520.17C1567.95 520.17 1500.66 579.679 1441.23 632.26Z"
      fill="white"
    />
    <path
      d="M1439.61 596.187C1387.11 647.952 1337.4 696.661 1235.65 691.158C1055.5 681.376 963.695 518.132 866.338 345.309C805.982 238.111 743.544 127.04 655.9 42.055C556.462 -54.3424 438.756 -101.216 296.306 -101.216C154.549 -101.216 44.4736 -69.016 -40.3954 -2.78096C-115.321 55.9134 -162.496 133.561 -207.821 208.763C-290.146 345.106 -368.077 474.111 -600.484 474.111V476.149C-366.69 476.149 -288.527 346.736 -205.971 209.782C-114.164 57.7476 -19.3516 -99.3822 296.074 -99.3822C613.35 -99.3822 746.55 137.23 864.026 345.921C961.614 519.355 1053.88 683.006 1235.18 692.992C1338.09 698.495 1388.27 649.379 1441 597.41C1502.05 537.9 1564.72 476.353 1712.25 476.353V474.315C1563.56 474.315 1497.65 539.123 1439.61 596.187Z"
      fill="white"
    />
    <path
      d="M1438.22 560.114C1386.65 616.159 1338.09 668.944 1232.64 664.052C1156.79 660.588 1092.27 625.942 1030.06 555.427C974.332 492.453 927.388 408.895 877.669 320.242C753.025 98.5076 611.962 -152.574 281.968 -152.574C138.592 -152.574 28.5171 -119.966 -54.2706 -53.1195C-127.577 6.18624 -171.746 84.6492 -214.527 160.463C-291.996 298.231 -365.071 428.256 -600.022 428.256V430.294C-363.684 430.294 -290.377 299.658 -212.446 161.482C-126.189 8.02041 -36.9267 -150.536 281.968 -150.536C610.344 -150.536 751.175 100.138 875.588 321.261C925.307 409.914 972.482 493.676 1028.21 556.854C1091.11 627.776 1155.86 662.626 1232.64 666.09C1339.25 670.982 1388.27 617.79 1440.07 561.337C1499.04 497.14 1560.32 430.498 1712.25 430.498V428.46C1559.17 428.46 1494.88 498.363 1438.22 560.114Z"
      fill="white"
    />
    <path
      d="M1436.6 524.042C1386.19 584.367 1338.55 641.227 1229.4 636.947C1076.54 631.037 989.594 475.945 889 296.397C757.65 62.0273 608.493 -203.728 267.167 -203.728C122.173 -203.728 12.3289 -170.916 -68.8401 -103.254C-140.528 -43.5411 -181.922 35.7371 -221.928 112.366C-294.541 251.357 -362.991 382.401 -600.254 382.401V384.439C-361.603 384.439 -292.691 252.58 -219.847 113.181C-138.909 -41.7069 -55.4276 -201.69 267.167 -201.69C607.105 -201.69 755.8 63.4539 886.919 297.416C936.638 386.069 983.581 469.831 1037.46 532.398C1097.82 602.505 1158.87 636.336 1229.4 639.189C1339.71 643.469 1387.81 586.201 1438.68 525.265C1496.5 456.176 1556.39 384.643 1712.48 384.643V382.605C1642.18 382.605 1584.83 397.482 1536.96 427.848C1494.65 454.75 1465.28 490.007 1436.6 524.042Z"
      fill="white"
    />
    <path
      d="M1435.21 487.765C1385.73 552.37 1339.01 613.51 1226.4 610.045C1086.72 605.766 1000.93 451.897 901.257 273.775C834.888 154.96 759.732 20.2481 658.675 -81.6519C543.05 -198.225 410.081 -254.882 252.599 -254.882C105.986 -254.882 -3.85798 -221.662 -83.1769 -153.593C-153.246 -93.2685 -191.865 -13.1751 -229.096 64.0651C-296.621 204.28 -360.446 336.546 -600.253 336.546V338.584C-359.059 338.584 -294.771 205.706 -227.015 64.8804C-151.627 -91.4342 -73.6956 -253.048 252.368 -253.048C604.331 -253.048 760.888 27.1774 898.944 274.387C998.845 453.119 1084.87 607.396 1225.93 611.88C1340.17 615.344 1387.11 553.797 1436.83 488.784C1493.72 415.009 1552 338.584 1712.25 338.584V336.546C1640.1 336.546 1581.83 352.442 1533.96 384.846C1491.87 413.582 1463.2 451.285 1435.21 487.765Z"
      fill="white"
    />
    <path
      d="M1433.83 451.285C1385.5 520.17 1339.71 585.386 1223.16 582.736C1096.43 580.087 1011.79 427.848 913.976 251.765C844.832 127.243 766.438 -13.9902 660.756 -121.8C539.35 -245.915 401.062 -306.239 238.03 -306.239C89.7986 -306.239 -20.0455 -272.816 -97.2832 -203.932C-165.733 -142.996 -201.577 -62.2908 -236.034 15.7647C-298.702 157.202 -357.903 290.691 -600.022 290.691V292.729C-356.284 292.729 -296.852 158.629 -233.721 16.5799C-163.883 -141.161 -91.502 -304.201 238.261 -304.201C602.944 -304.201 774.532 4.55565 912.357 252.784C1010.64 429.479 1095.28 582.125 1223.62 584.774C1341.56 587.424 1387.58 521.8 1436.37 452.304C1490.95 373.841 1547.83 292.729 1712.25 292.729V290.691C1638.02 290.691 1578.82 307.403 1531.18 341.845C1489.1 372.415 1461.11 412.563 1433.83 451.285Z"
      fill="white"
    />
    <path
      d="M1432.44 414.805C1385.5 487.358 1341.1 555.835 1223.62 555.835C1222.46 555.835 1221.31 555.835 1220.15 555.835C1105.91 554.612 1023.13 404.819 927.62 231.385C782.857 -30.9057 602.481 -357.597 223.461 -357.597C73.3798 -357.597 -36.0018 -323.766 -111.621 -254.271C-178.452 -192.723 -211.29 -111.203 -243.202 -32.5361C-300.784 110.124 -355.359 244.836 -600.022 244.836V246.874C-353.74 246.874 -298.934 111.55 -240.89 -31.721C-176.602 -190.889 -110.002 -355.355 223.693 -355.355C601.325 -355.355 781.238 -29.4791 925.77 232.404C973.638 319.427 1018.96 401.354 1065.91 460.456C1118.17 526.284 1167.2 557.261 1220.38 557.873C1342.02 559.299 1387.11 489.6 1434.75 415.824C1488.17 332.674 1543.67 246.874 1712.25 246.874V244.836C1636.17 244.836 1576.05 262.566 1528.41 299.047C1486.55 331.247 1459.03 373.637 1432.44 414.805Z"
      fill="white"
    />
    <path
      d="M1232.87 32.6801C1121.87 26.5661 1026.59 -69.8313 916.057 -181.31C846.913 -251.213 768.288 -330.695 676.019 -396.523C570.106 -472.133 462.112 -519.007 345.331 -539.794C298.618 -548.15 253.524 -552.226 207.736 -552.226C205.193 -552.226 202.88 -552.226 200.336 -552.226C-103.064 -548.761 -173.596 -438.709 -241.815 -332.529C-304.484 -234.909 -363.684 -142.792 -600.253 -142.792V-140.754C-362.296 -140.754 -302.865 -233.483 -239.965 -331.511C-171.977 -437.283 -101.677 -546.723 200.336 -550.188C248.437 -550.595 295.843 -546.52 344.868 -537.756C606.181 -491.494 769.907 -326.008 914.438 -180.087C1025.21 -68.2009 1120.71 28.4002 1232.87 34.5142C1334.62 40.0168 1388.96 -0.743096 1446.55 -43.7449C1509.91 -91.4341 1575.58 -140.754 1712.25 -140.754V-142.792C1574.66 -142.792 1508.75 -93.2683 1444.7 -45.1715C1387.58 -2.37352 1333.7 38.1827 1232.87 32.6801Z"
      fill="white"
    />
    <path
      d="M1230.79 94.6352C1120.71 89.3364 1027.06 -13.7864 918.832 -133.213C850.382 -208.619 772.913 -294.215 680.875 -365.545C575.425 -447.473 466.737 -498.219 349.031 -520.841C301.855 -530.012 254.218 -534.495 207.274 -534.495C205.424 -534.495 203.343 -534.495 201.493 -534.495C-106.533 -531.438 -175.908 -414.457 -242.971 -301.348C-304.252 -198.022 -362.296 -100.198 -600.253 -100.198V-98.1596C-360.909 -98.1596 -302.634 -196.391 -240.89 -300.329C-174.058 -413.031 -104.914 -529.4 201.724 -532.457C250.286 -532.865 299.774 -528.178 348.799 -518.803C612.194 -468.26 781.469 -281.783 917.213 -131.99C1025.9 -12.1561 1119.79 91.3744 1230.79 96.6732C1335.55 101.768 1388.27 56.7284 1444.46 9.24303C1506.44 -43.3374 1570.96 -97.9558 1712.25 -97.9558V-99.9938C1570.03 -99.9938 1505.28 -45.1716 1442.61 7.8164C1387.11 55.098 1334.39 99.5264 1230.79 94.6352Z"
      fill="white"
    />
    <path
      d="M1440.76 60.8044C1386.42 112.366 1335.31 160.87 1228.94 156.59C1119.79 152.107 1028.21 42.4623 921.838 -84.7089C854.313 -165.617 777.538 -257.327 685.963 -334.16C580.513 -422.405 471.593 -477.024 352.731 -501.48C304.63 -511.466 255.837 -516.561 207.274 -516.561C205.655 -516.561 204.036 -516.561 202.418 -516.561C-110.002 -513.912 -178.221 -389.797 -244.358 -269.963C-304.483 -160.726 -361.371 -57.6035 -600.484 -57.6035V-55.5655C-359.984 -55.5655 -302.865 -159.3 -242.277 -269.148C-176.602 -388.575 -108.614 -512.077 202.418 -514.727C252.137 -515.134 302.549 -510.04 352.037 -499.85C617.975 -445.027 785.401 -244.896 919.757 -83.8936C1026.36 43.6852 1118.4 153.737 1228.48 158.221C1336.01 162.704 1387.58 113.589 1442.15 61.8234C1503.2 4.55556 1566.33 -55.3616 1712.25 -55.3616V-57.3996C1565.18 -57.3996 1502.05 2.72136 1440.76 60.8044Z"
      fill="white"
    />
    <path
      d="M1438.91 113.793C1385.96 169.634 1336.24 222.418 1226.86 218.546C1118.86 214.877 1028.91 98.7113 924.845 -36.0005C858.013 -122.412 782.394 -220.439 690.819 -302.571C585.6 -397.134 476.218 -455.828 356.199 -482.322C307.405 -493.124 257.224 -498.626 207.274 -498.626C206.118 -498.626 204.73 -498.626 203.343 -498.626C61.586 -497.607 -42.708 -470.298 -115.783 -414.865C-180.302 -365.749 -213.602 -301.144 -245.515 -238.578C-304.252 -123.227 -359.753 -14.6015 -600.253 -14.6015V-12.5635C-358.365 -12.5635 -302.402 -121.8 -243.202 -237.559C-178.683 -363.711 -111.852 -494.347 203.574 -496.588C254.68 -496.996 305.787 -491.493 355.737 -480.284C624.45 -421.182 790.025 -206.989 922.995 -34.7777C1027.52 100.342 1117.48 216.915 1226.63 220.584C1336.93 224.252 1387.35 171.264 1440.53 115.015C1500.2 52.4487 1561.71 -12.5635 1712.25 -12.5635V-14.6015C1560.55 -14.6015 1498.81 50.6145 1438.91 113.793Z"
      fill="white"
    />
    <path
      d="M1437.3 166.781C1385.73 226.902 1337.16 283.762 1225.01 280.705C1117.94 277.851 1030.06 155.164 928.082 13.1153C862.176 -78.7985 787.251 -183.144 695.907 -270.982C590.919 -371.863 481.075 -434.837 359.668 -463.165C309.949 -474.782 258.843 -480.692 207.274 -480.692C206.349 -480.692 205.193 -480.692 204.268 -480.692C60.43 -479.877 -44.7892 -451.141 -117.864 -392.854C-182.615 -341.293 -215.221 -273.02 -246.671 -206.989C-304.252 -86.1353 -358.827 27.9926 -600.484 27.9926V30.0306C-357.209 30.0306 -302.402 -84.7087 -244.358 -206.173C-180.764 -339.255 -115.321 -476.82 204.499 -478.654C256.762 -478.858 308.793 -472.948 359.206 -461.127C630.694 -397.542 794.419 -169.286 926.001 14.1343C1028.21 156.59 1116.55 279.686 1224.55 282.539C1337.86 285.596 1386.88 228.328 1438.68 167.8C1497.19 100.138 1557.32 30.2345 1712.25 30.2345V28.1965C1556.16 28.1965 1495.57 98.5075 1437.3 166.781Z"
      fill="white"
    />
    <path
      d="M1435.68 219.565C1386.42 282.743 1339.71 342.66 1229.4 342.66C1227.32 342.66 1225.24 342.66 1223.16 342.66C1117.48 340.622 1031.45 211.616 931.782 62.4347C801.357 -132.806 639.019 -375.735 363.831 -443.805C313.418 -456.236 261.155 -462.554 207.968 -462.554C207.274 -462.554 206.58 -462.554 205.886 -462.554C60.1984 -461.943 -46.1767 -431.984 -119.483 -370.64C-184.233 -316.429 -216.146 -244.692 -247.134 -175.4C-303.559 -48.8401 -356.746 70.7905 -600.022 70.7905V72.8285C-355.359 72.8285 -301.709 -47.4134 -245.052 -174.585C-182.615 -314.595 -118.327 -459.089 205.886 -460.516C259.536 -460.72 312.262 -454.402 363.137 -441.767C637.4 -373.901 799.507 -131.379 929.701 63.6575C979.882 138.656 1027.06 209.375 1074.23 260.325C1126.73 316.981 1173.9 343.883 1223.16 344.698C1225.24 344.698 1227.32 344.698 1229.63 344.698C1341.33 344.698 1388.27 284.373 1437.99 220.787C1494.18 148.031 1552.69 72.8285 1712.25 72.8285V70.7905C1551.53 70.7905 1492.56 146.4 1435.68 219.565Z"
      fill="white"
    />
    <path
      d="M1434.06 272.553C1385.73 340.622 1339.94 404.819 1225.24 404.819C1223.85 404.819 1222.46 404.819 1221.08 404.819C1116.55 403.392 1032.38 268.477 935.02 112.366C805.75 -94.6948 645.263 -351.89 367.068 -424.443C315.962 -437.894 262.543 -444.619 207.968 -444.619C207.505 -444.619 207.043 -444.619 206.58 -444.619C58.8111 -444.212 -48.4892 -412.827 -121.796 -348.63C-186.777 -291.769 -217.996 -216.567 -248.29 -143.811C-303.559 -11.3407 -355.59 113.589 -600.022 113.589V115.627C-353.971 115.627 -301.477 -10.1179 -245.977 -143.199C-184.696 -289.935 -121.565 -441.766 207.043 -442.581C207.505 -442.581 207.968 -442.581 208.43 -442.581C262.774 -442.581 315.962 -435.856 366.837 -422.609C643.644 -350.26 803.9 -93.2682 932.707 113.181C981.732 191.848 1027.98 266.031 1074.23 319.223C1125.57 378.325 1172.28 406.245 1220.85 406.857C1222.23 406.857 1223.62 406.857 1225.01 406.857C1341.1 406.857 1387.11 342.048 1435.91 273.572C1491.18 195.924 1548.3 115.627 1712.25 115.627V113.589C1546.91 113.589 1489.56 194.293 1434.06 272.553Z"
      fill="white"
    />
    <path
      d="M1432.44 325.337C1384.8 398.501 1339.71 467.589 1219 466.774C1171.59 466.366 1126.5 437.834 1076.54 376.898C1031.22 321.668 986.126 244.225 938.257 162.093C810.607 -56.3804 651.738 -328.25 370.768 -405.286C318.968 -419.552 264.393 -426.685 208.43 -426.685C208.199 -426.685 207.968 -426.685 207.736 -426.685C58.1173 -426.481 -50.3393 -393.669 -123.877 -326.619C-188.858 -267.11 -219.615 -188.443 -249.446 -112.425C-303.327 25.751 -354.434 156.183 -600.253 156.183V158.221C-352.815 158.221 -301.478 27.1775 -247.365 -111.814C-187.24 -265.479 -125.265 -424.443 207.505 -424.851C207.736 -424.851 207.968 -424.851 208.199 -424.851C263.93 -424.851 318.505 -417.718 369.843 -403.452C649.888 -326.619 808.526 -55.1577 935.713 162.908C983.582 245.04 1028.91 322.484 1074.23 377.917C1124.65 439.465 1170.43 468.201 1218.53 468.608C1219.23 468.608 1219.92 468.608 1220.61 468.608C1341.33 468.608 1386.42 399.316 1434.06 326.152C1488.17 243.613 1543.9 158.221 1712.25 158.221V156.183C1542.51 156.183 1486.55 242.187 1432.44 325.337Z"
      fill="white"
    />
    <path
      d="M1430.82 375.276C1384.34 451.381 1340.63 523.279 1216.91 523.279C1114.7 523.279 1038.16 386.091 941.495 212.252C866.801 78.0676 781.932 -73.9412 666.538 -193.105C532.875 -331.095 383.256 -397.987 208.662 -397.987C56.9611 -397.987 -52.4205 -364.541 -125.958 -295.446C-191.171 -234.362 -221.233 -153.651 -250.371 -75.5434C-303.096 65.8509 -353.046 199.434 -600.253 199.434V201.437C-351.428 201.437 -301.246 67.2527 -248.29 -74.7424C-189.321 -232.559 -128.502 -395.784 208.662 -395.784C599.938 -395.784 788.175 -58.1195 939.413 213.253C986.357 297.369 1030.53 376.878 1074.93 433.356C1124.18 496.242 1169.28 525.482 1216.91 525.482C1342.25 525.482 1386.19 452.983 1432.9 376.277C1485.16 290.359 1539.28 201.437 1712.25 201.437V199.434C1537.89 199.434 1483.31 288.957 1430.82 375.276Z"
      fill="white"
    />
  </svg>
);
