export const BannerBgWave = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 986 381"
    >
      <g clipPath="url(#a)">
        <g opacity=".06" clipPath="url(#b)" fill="#fff">
          <path d="M1331.78 830.219c-320.99-51.357-383.552-190.96-438.781-313.852-45.448-101.085-84.578-188.311-267.182-188.311-240.077 0-383.756 139.399-522.543 274.315-125.338 121.668-243.542 236.408-422.07 236.408v2.038c179.344 0 297.752-115.147 423.496-237.02 138.584-134.508 282.059-273.703 521.321-273.703 181.382 0 220.307 86.615 265.347 187.088 26.494 59.102 56.657 125.949 119.832 183.42 72.35 65.624 177.31 108.83 320.58 131.655 79.07 12.636 161.41 11.413 265.55 9.783 37.91-.408 77.65-1.019 121.67-1.019v-2.038c-44.02 0-83.76.611-122.08 1.222-104.14 1.427-186.27 2.65-265.14-9.986Z" />
          <path d="M1328.93 803.113c-306.52-45.855-373.161-191.368-431.855-319.558-50.747-110.867-94.564-206.653-284.301-206.653-243.338 0-381.922 141.029-516.226 277.372C-24.305 677.165-138.433 793.127-319 793.127v2.038c181.382 0 295.918-116.37 416.975-239.465C231.87 419.562 370.252 278.94 612.774 278.94c188.311 0 231.924 95.174 282.466 205.43 29.755 65.012 60.529 132.266 123.3 190.757 71.53 66.846 173.03 109.441 310.18 130.024 66.65 9.987 130.43 4.892 204.21-1.019 53.4-4.279 113.92-9.171 186.07-9.171v-2.038c-72.15 0-132.67 4.892-186.07 9.171-73.78 5.911-137.56 11.006-204 1.019Z" />
          <path d="M1326.28 776.008c-292.25-40.963-362.563-191.775-424.518-325.061-27.717-59.713-54.007-116.166-98.028-157.537-49.116-46.263-113.924-67.865-204.004-67.865-246.598 0-380.29 142.456-509.703 280.224-116.37 124.115-226.422 241.3-409.231 241.3v2.038c183.624 0 293.88-117.593 410.657-241.911C220.46 369.631 353.948 227.583 599.527 227.583c195.648 0 243.948 103.937 299.993 224.383 62.159 133.693 132.67 285.117 426.15 326.08 64.8 8.968 121.46.204 186.88-9.986 57.88-8.763 122.89-18.953 206.45-18.953v-2.038c-83.76 0-148.77 10.19-206.45 18.953-65.42 10.394-121.87 19.157-186.27 9.986Z" />
          <path d="M1323.63 748.903c-278.19-36.481-351.761-192.388-416.569-329.953-30.366-64.604-59.306-125.54-105.976-170.58-52.581-50.746-120.446-74.183-213.786-74.183-249.859 0-378.661 144.086-503.183 283.282-112.09 125.337-217.862 243.744-402.912 243.744v2.038c185.865 0 292.045-118.611 404.339-244.356 124.318-138.991 252.712-282.67 501.552-282.67 202.984 0 256.38 112.905 318.132 243.744 33.219 70.515 67.661 143.476 129.213 204.208 69.9 69.088 164.46 110.46 288.98 126.967 65.42 8.56 118.41-3.872 179.96-18.342 58.29-13.858 124.52-29.551 215.62-29.551v-2.038c-91.3 0-157.74 15.693-216.23 29.348-61.35 14.469-114.13 26.901-179.14 18.342Z" />
          <path d="M1320.77 722.001c-264.12-32.404-337.488-185.865-408.41-334.232-33.22-69.292-64.401-134.915-114.332-183.827-56.045-55.026-127.375-80.705-223.976-80.705-253.12 0-376.827 145.513-496.661 286.339C-30.012 535.728-131.708 655.359-319 655.359v2.038c188.107 0 290.211-119.835 398.021-246.802 119.427-140.418 242.93-285.524 495.031-285.524 210.525 0 271.665 127.986 336.473 263.513 34.85 72.961 70.923 148.367 131.455 209.914 68.68 69.904 159.78 110.868 278.39 125.337 67.25 8.152 118-7.948 176.69-26.697 58.7-18.546 125.13-39.741 221.94-39.741v-2.038c-97.21 0-163.65 21.195-222.35 39.944-58.49 18.75-109.03 34.85-175.88 26.698Z" />
          <path d="M1318.13 694.896c-250.27-28.736-326.085-186.07-399.656-338.105C847.755 210.259 780.909 71.879 561.212 71.879c-256.38 0-375.195 147.144-490.139 289.192C-32.253 488.854-129.67 609.503-319.204 609.503v2.038c190.35 0 288.173-121.057 391.704-249.247 114.535-141.845 233.147-288.58 488.508-288.58 218.474 0 284.913 137.972 355.428 283.893 36.276 75.202 73.775 152.85 133.484 215.212 67.67 70.515 155.1 111.071 267.59 123.911 69.7 7.948 118.62-12.024 175.48-35.054 58.49-23.437 124.11-50.135 226.01-50.135v-2.038c-102.31 0-168.13 26.902-226.42 50.543-56.45 22.825-105.37 42.594-174.45 34.85Z" />
          <path d="M1315.27 667.79c-236.61-25.271-314.67-185.865-390.275-341.365-38.722-79.482-75.202-154.276-131.247-211.34-63.586-64.605-141.641-94.563-245.171-94.563-259.642 0-373.362 148.57-483.618 292.249C-33.884 441.776-127.224 563.649-319 563.649v2.038c192.591 0 286.339-122.28 385.386-251.693C176.234 170.722 289.75 22.56 548.373 22.56c226.421 0 298.363 147.958 374.788 304.884 75.814 155.907 154.279 317.113 391.909 342.384 72.35 7.745 120.04-15.896 175.27-43.409 57.26-28.532 122.28-60.732 228.66-60.732v-2.038c-106.79 0-172.01 32.404-229.48 60.936-55.02 27.309-102.51 50.746-174.25 43.205Z" />
          <path d="M1312.62 640.685c-222.95-22.215-303.25-185.662-380.696-344.015-39.537-80.908-80.501-164.466-140.01-225.606-66.643-68.477-150.405-101.9-256.381-101.9-117.592 0-215.213 30.57-297.955 93.748C164.21 118.753 110.61 192.732 58.641 264.47c-94.563 130.228-183.827 253.323-377.437 253.323v2.038c194.832 0 284.3-123.503 379.272-254.138C165.228 120.791 273.65-28.798 535.533-28.798c234.574 0 312.222 158.556 394.557 326.487 77.65 158.557 158.15 322.819 382.33 345.034 75.2 7.54 123.91-21.399 175.67-51.969 56.46-33.22 120.25-70.923 230.91-70.923v-2.038c-111.27 0-175.27 37.907-231.92 71.33-53.6 31.589-100.07 58.898-174.46 51.562Z" />
          <path d="M1309.77 613.579c-209.71-19.361-291.43-185.254-370.509-345.848-42.39-85.8-86.003-174.657-148.978-240.28C719.769-46.121 632.135-81.99 522.49-81.99c-119.019 0-216.435 30.977-297.752 94.766C152.797 69.23 101.643 144.024 52.12 216.373-37.96 347.824-123.148 472.142-319 472.142v2.038c197.074 0 282.467-124.725 372.954-256.584C154.02 71.064 257.754-79.952 522.694-79.952c243.133 0 330.36 177.306 414.733 348.702 39.741 80.501 80.703 163.651 137.153 227.644 63.59 71.738 140.42 110.664 234.99 119.427 78.05 7.133 125.94-25.679 176.49-60.528 55.43-38.315 118.2-81.52 232.94-81.52v-2.038c-115.35 0-178.33 43.409-233.96 81.52-52.38 36.276-97.83 67.457-175.27 60.324Z" />
          <path d="M1307.12 586.474c-196.67-16.507-275.94-177.102-360.114-347.071-90.691-183.216-184.236-372.75-437.355-372.75-120.65 0-217.862 31.385-297.548 95.582C141.588 19.299 92.88 94.705 45.598 167.665c-85.8 132.674-166.708 258.011-364.802 258.011v2.038c199.317 0 280.633-125.744 366.637-259.03C142.81 21.134 241.45-131.513 509.65-131.513c251.693 0 345.237 188.923 435.52 371.527 40.353 81.724 82.339 166.505 137.569 230.498 61.95 71.738 135.32 110.256 223.97 117.797 81.12 6.929 127.99-29.959 177.51-69.089 54.62-42.594 116.17-91.302 234.78-91.302v-2.038c-119.43 0-181.18 48.708-235.8 91.914-49.11 38.722-95.58 75.406-176.08 68.68Z" />
          <path d="M1304.47 559.369c-183.83-14.063-263.92-176.084-348.905-347.479-96.601-195.037-196.463-396.595-458.55-396.595-122.076 0-219.289 31.589-297.14 96.6C130.786-30.631 84.524 45.794 39.688 119.365-41.628 253.261-118.461 379.821-319 379.821v2.038c201.558 0 278.594-126.968 360.318-261.476 90.487-148.978 184.235-303.254 455.493-303.254 260.661 0 360.319 200.947 456.716 395.372 85.193 172.007 165.693 334.436 350.533 348.702 84.38 6.521 130.23-34.442 178.74-77.852 53.39-47.281 113.72-101.288 236.2-101.288v-2.038c-123.3 0-184.03 54.21-237.43 101.9-48.09 43.205-93.75 83.965-177.1 77.444Z" />
          <path d="M1480.15 446.26c-47.29 47.281-91.92 92.117-178.53 86.207-171.19-11.82-251.9-174.657-337.495-347.071C913.582 83.699 861.41-21.665 788.042-101.351c-83.151-90.283-182.605-134.304-304.07-134.304-123.503 0-220.512 31.996-296.937 97.62C119.374-79.952 75.557-2.915 33.166 71.471c-77.036 135.12-149.792 262.902-352.37 262.902v2.038c203.8 0 276.76-128.19 354-263.92 85.801-150.405 174.25-306.108 448.972-306.108 270.035 0 376.011 213.582 478.319 420.032C1047.89 359.441 1129 522.685 1301.21 534.709c87.63 6.114 132.47-38.926 179.96-86.615 52.37-52.784 111.47-111.886 237.83-111.886v-2.038c-127.17 0-186.48 59.509-238.85 112.09Z" />
          <path d="M1478.72 410.187c-46.26 51.765-90.08 100.474-179.75 94.971-158.76-9.782-239.67-173.026-325.47-345.849-53.192-107.198-108.218-218.27-185.458-303.254-87.634-96.397-191.369-143.271-316.909-143.271-124.93 0-221.939 32.2-296.733 98.435C108.369-130.087 66.793-52.439 26.849 22.763c-72.553 136.343-141.234 265.348-346.053 265.348v2.038c206.042 0 274.927-129.413 347.683-266.367 80.909-152.034 164.467-309.164 442.45-309.164 279.613 0 397.002 236.612 500.533 445.303 86.008 173.434 167.318 337.085 327.098 347.071 90.69 5.503 134.92-43.613 181.38-95.582 53.81-59.51 109.04-121.057 239.06-121.057v-2.038c-131.04 0-189.13 64.808-240.28 121.872Z" />
          <path d="M1477.5 374.115c-45.45 56.045-88.25 108.829-181.18 103.938-66.85-3.465-123.71-38.111-178.53-108.626-49.12-62.974-90.49-146.532-134.304-235.185C873.638-87.492 749.32-338.574 458.497-338.574c-126.356 0-223.365 32.608-296.325 99.455C97.567-179.814 58.642-101.351 20.938-25.537c-68.273 137.769-132.674 267.793-339.734 267.793v2.038c208.283 0 272.888-130.636 341.568-268.812C98.79-177.98 177.457-336.536 458.498-336.536c289.396 0 413.51 250.674 523.155 471.797 43.818 88.653 85.388 172.415 134.508 235.593 55.43 70.922 112.5 105.772 180.16 109.237 93.95 4.891 137.16-48.301 182.81-104.754C1531.1 311.14 1585.1 244.498 1719 244.498v-2.038c-134.92 0-191.57 69.903-241.5 131.655Z" />
          <path d="M1476.07 338.042c-44.43 60.325-86.41 117.185-182.61 112.905-134.71-5.91-211.34-161.002-299.989-340.55-115.758-234.37-247.209-500.125-548.018-500.125-127.782 0-224.587 32.812-296.121 100.474-63.178 59.713-99.658 138.991-134.916 215.62C-49.577 65.358-109.902 196.4-319 196.4v2.038c210.321 0 271.053-131.859 335.25-271.258C87.58-227.707 161.152-387.69 445.453-387.69c299.586 0 430.63 265.144 546.184 499.106 43.813 88.653 85.193 172.415 132.673 234.982 53.19 70.107 107 103.938 169.15 106.791 97.22 4.28 139.61-52.988 184.44-113.924 50.95-69.088 103.74-140.622 241.3-140.622v-2.038c-61.95 0-112.49 14.877-154.68 45.243-37.3 26.902-63.18 62.159-88.45 96.194Z" />
          <path d="M1474.85 301.765c-43.62 64.605-84.78 125.745-184.03 122.28-123.1-4.279-198.71-158.148-286.55-336.27C945.783-31.04 879.548-165.751 790.487-267.651c-101.9-116.573-219.085-173.23-357.873-173.23-129.209 0-226.014 33.22-295.917 101.289C74.945-279.268 40.911-199.175 8.099-121.935-51.411 18.28-107.66 150.546-319 150.546v2.038c212.563 0 269.22-132.878 328.933-273.704 66.439-156.314 135.12-317.928 422.478-317.928 310.183 0 448.156 280.225 569.829 527.435 88.04 178.732 163.85 333.009 288.17 337.493 100.68 3.464 142.05-58.083 185.86-123.096 50.14-73.775 101.5-150.2 242.73-150.2v-2.038c-63.59 0-114.94 15.896-157.13 48.3-37.09 28.736-62.36 66.439-87.02 102.919Z" />
          <path d="M1473.62 265.285c-42.59 68.885-82.94 134.101-185.66 131.451-111.68-2.649-186.27-154.888-272.48-330.97C954.546-58.758 885.458-199.99 792.321-307.8 685.326-431.915 563.454-492.239 419.775-492.239c-130.636 0-227.441 33.423-295.51 102.307C63.94-328.996 32.351-248.291 1.985-170.235c-55.23 141.437-107.403 274.926-320.781 274.926v2.038c214.805 0 267.181-134.1 322.819-276.149C65.57-327.161 129.36-490.201 419.979-490.201c321.392 0 472.612 308.757 594.081 556.985 86.61 176.695 161.2 329.341 274.31 331.99 103.94 2.65 144.49-62.974 187.5-132.47 48.09-78.463 98.23-159.575 243.13-159.575v-2.038c-65.42 0-117.59 16.712-159.58 51.154-37.09 30.57-61.75 70.718-85.8 109.44Z" />
          <path d="M1472.4 228.805c-41.37 72.553-80.5 141.03-184.03 141.03h-3.06c-100.67-1.223-173.63-151.016-257.8-324.45-127.582-262.291-286.546-588.982-620.574-588.982-132.267 0-228.664 33.831-295.307 103.326C52.731-378.723 23.792-297.203-4.333-218.536-55.079-75.876-103.176 58.836-318.796 58.836v2.038c217.047 0 265.347-135.324 316.501-278.595C54.362-376.889 113.056-541.355 407.14-541.355c332.806 0 491.362 325.876 618.741 587.759 42.18 87.023 82.13 168.95 123.5 228.052 46.06 65.828 89.26 96.805 136.14 97.417 107.2 1.426 146.94-68.273 188.92-142.049 47.08-83.15 95.99-168.95 244.56-168.95v-2.038c-67.05 0-120.04 17.73-162.02 54.211-36.89 32.2-61.14 74.59-84.58 115.758Z" />
          <path d="M1478.11-72.207c-46.67 55.84-90.49 108.625-186.89 104.753C1196.05 28.877 1116.77-87.29 1025.06-222c-58.897-86.412-125.54-184.439-206.245-266.571-92.729-94.563-189.126-153.257-294.898-179.751-43.002-10.802-87.227-16.304-131.247-16.304h-3.465c-124.929 1.019-216.843 28.328-281.244 83.761-56.86 49.116-86.207 113.721-114.332 176.287C-58.136-309.227-107.048-200.601-319-200.601v2.038c213.175 0 262.494-109.237 314.667-224.996 56.86-126.152 115.758-256.787 393.742-259.029 45.039-.408 90.079 5.095 134.1 16.304 236.816 59.102 382.737 273.295 499.921 445.506 92.12 135.12 171.4 251.693 267.59 255.362 97.21 3.668 141.64-49.32 188.51-105.569 52.59-62.566 106.8-127.578 239.47-127.578v-2.038c-133.69 0-188.11 65.216-240.89 128.394Z" />
          <path d="M1476.68-19.22c-45.45 60.121-88.24 116.982-187.09 113.925-94.36-2.853-171.8-125.541-261.68-267.59-58.079-91.914-124.11-196.259-204.611-284.097-92.525-100.881-189.33-163.855-296.325-192.183-43.817-11.617-88.857-17.527-134.304-17.527h-2.65c-126.763.815-219.492 29.551-283.893 87.838C49.063-527.293 20.327-459.02-7.39-392.989c-50.746 120.854-98.843 234.982-311.814 234.982v2.038c214.398 0 262.699-114.74 313.852-236.204C50.693-525.255 108.37-662.82 390.224-664.654a521.342 521.342 0 0 1 136.342 17.527c239.262 63.585 383.552 291.841 499.514 475.261 90.08 142.457 167.93 265.552 263.11 268.405 99.86 3.057 143.06-54.21 188.71-114.74 51.57-67.661 104.55-137.564 241.1-137.564v-2.038c-137.56 0-190.96 70.31-242.32 138.583Z" />
          <path d="M1475.26 33.565c-43.41 63.178-84.58 123.095-181.79 123.095h-5.51c-93.13-2.038-168.95-131.044-256.79-280.225-114.938-195.241-258.006-438.17-500.528-506.239-44.428-12.432-90.487-18.75-137.361-18.75h-1.834c-128.394.611-222.142 30.57-286.747 91.914C47.636-502.429 19.512-430.692-7.797-361.4-57.525-234.84-104.4-115.209-318.796-115.209v2.038c215.62 0 262.902-120.242 312.833-247.414 55.026-140.01 111.682-284.504 397.41-285.931 47.281-.204 93.748 6.114 138.584 18.75 241.706 67.865 384.57 310.387 499.309 505.424 44.23 74.998 85.8 145.717 127.38 196.667 46.26 56.656 87.83 83.558 131.24 84.373h5.71c98.43 0 139.81-60.325 183.62-123.91 49.53-72.757 101.09-147.959 241.71-147.959v-2.038c-141.64 0-193.61 75.61-243.74 148.774Z" />
          <path d="M1473.83 86.553c-42.6 68.069-82.95 132.266-184.03 132.266h-3.67c-92.12-1.427-166.3-136.342-252.1-292.453C920.104-280.695 778.667-537.89 533.495-610.443c-45.039-13.451-92.117-20.176-140.214-20.176h-1.223c-130.228.407-224.791 31.792-289.396 95.989-57.268 56.861-84.78 132.063-111.478 204.819-48.709 132.47-94.564 257.4-309.98 257.4v2.038c216.843 0 263.106-125.745 312.018-258.826 54.006-146.736 109.644-298.567 399.244-299.382h1.223c47.893 0 94.767 6.725 139.603 19.972C777.24-536.26 918.474-279.268 1031.99-72.819c43.21 78.667 83.97 152.85 124.73 206.042 45.24 59.102 86.41 87.022 129.2 87.634h3.67c102.31 0 142.87-64.809 185.87-133.285C1524.17 9.924 1574.51-70.373 1719-70.373v-2.038c-145.72 0-196.26 80.705-245.17 158.964Z" />
          <path d="M1472.4 139.337c-41.98 73.164-81.72 142.252-188.11 141.437-41.77-.407-81.52-28.939-125.54-89.876-39.94-55.229-79.68-132.673-121.87-214.805C924.384-242.38 784.373-514.25 536.756-591.286c-45.651-14.266-93.748-21.399-143.067-21.399h-.612c-131.858.204-227.441 33.016-292.249 100.066C43.56-453.11 16.455-374.443-9.835-298.425-57.321-160.249-102.361-29.817-319-29.817v2.038c218.066 0 263.31-131.043 310.999-270.035C44.987-451.479 99.605-610.443 392.873-610.851h.612c49.115 0 97.212 7.133 142.456 21.399 246.802 76.833 386.609 348.294 498.699 566.36 42.19 82.132 82.13 159.576 122.08 215.009 44.42 61.548 84.78 90.284 127.17 90.691h1.83c106.38 0 146.13-69.292 188.11-142.456 47.69-82.539 96.8-167.931 245.17-167.931v-2.038c-149.59 0-198.91 86.004-246.6 169.154Z" />
          <path d="M1470.98 189.276c-40.97 76.105-79.49 148.003-188.52 148.003-90.08 0-157.54-137.188-242.73-311.027-65.823-134.184-140.617-286.193-242.314-405.357-117.796-137.99-249.655-204.882-403.524-204.882-133.692 0-230.09 33.446-294.898 102.541-57.472 61.084-83.966 141.795-109.645 219.903C-57.117-120.149-101.138 13.434-319 13.434v2.003c219.289 0 263.513-134.184 310.184-276.179C43.153-418.559 96.752-581.784 393.892-581.784c344.83 0 510.723 337.664 644.008 609.037 41.37 84.116 80.3 163.625 119.43 220.103 43.41 62.886 83.15 92.126 125.13 92.126 110.46 0 149.18-72.499 190.35-149.205 46.06-85.918 93.75-174.84 246.19-174.84v-2.003c-153.67 0-201.76 89.523-248.02 175.842Z" />
          <path d="M889.126 549.587c-20.176-45.855-39.129-89.265-74.794-120.039-39.334-34.238-95.175-50.134-175.88-50.134-236.815 0-385.385 137.972-529.064 271.461C-20.229 771.117-142.713 884.838-319 884.838v2.038c177.102 0 299.79-113.925 429.814-234.574 143.475-133.082 291.842-270.85 527.842-270.85 174.453 0 207.265 74.59 248.84 168.95 32.404 73.979 69.292 157.741 183.824 223.365 132.27 76.017 344.02 113.109 647.68 113.109v-2.038c-303.25 0-514.8-36.888-646.86-112.702-113.925-65.42-150.609-148.978-183.014-222.549Z" />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <rect width="986" height="381" rx="24" fill="#fff" />
        </clipPath>
        <clipPath id="b">
          <path
            fill="#fff"
            transform="rotate(-180 859.5 516.5)"
            d="M0 0h2038v2038H0z"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
