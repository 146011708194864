export const BannerBg = ({ className }: { className?: string }) => {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1112 272"
      className={className}
    >
      <g opacity=".05" clipPath="url(#a)" fill="#fff">
        <path d="M-320.133-922.06c315.947 31.48 429.735 263.097 539.479 487.495 56.027 114.653 114.076 233.061 198.405 319.701 94.438 97.037 213.135 144.4 363.311 144.4 166.637 0 304.968-43.32 422.228-132.848 103.97-79.131 179.92-183.965 253.56-285.623 134.01-184.543 260.5-358.978 534.86-358.978v-2.888c-276.09 0-402.87 175.013-537.46 360.134-148.44 205.336-302.08 417.315-673.188 417.315-332.409 0-442.442-224.686-559.117-462.657C111.912-660.696-2.164-893.468-319.844-924.948c-106.567-10.685-175.59 30.324-248.946 73.644-79.997 47.075-170.392 100.503-327.21 100.503v2.888c157.685 0 248.368-53.717 328.654-101.08 75.955-44.764 141.801-83.463 247.213-73.067Z" />
        <path d="M-316.09-883.649C-18.914-856.213 96.894-621.13 208.949-393.556c60.07 121.585 121.873 247.502 211.113 340.495C519.986 51.197 644.17 102.026 799.545 102.026c168.659 0 306.705-43.898 421.935-134.292 101.95-79.997 174.44-185.987 244.62-288.511 127.64-186.276 248.36-362.444 525.9-362.444v-2.888c-279.27 0-400.28 176.746-528.5 363.599-141.8 207.647-288.8 421.648-664.244 421.648-344.538 0-468.145-251.256-587.708-494.137C155.232-509.076 97.183-626.906 17.186-717.589-72.92-819.247-181.798-874.408-315.801-886.826c-110.61-10.108-178.478 36.389-250.101 85.774-78.553 54.294-167.504 115.52-330.098 115.52v2.888c163.461 0 252.7-61.515 331.542-115.52 74.222-51.407 138.624-95.593 248.368-85.485Z" />
        <path d="M-312.335-845.239C-33.643-821.847 78.7-594.272 197.974-353.413 326.49-93.782 459.05 174.802 817.739 174.802c170.97 0 308.731-44.475 421.651-135.447 99.92-80.864 168.94-187.72 235.95-291.11 121.58-188.009 236.23-365.621 516.95-365.621v-2.888c-282.45 0-397.68 178.189-519.55 367.065-135.16 209.09-274.94 425.402-655.001 425.402-356.668 0-489.227-267.717-617.165-526.482-57.183-115.809-116.676-235.95-194.94-326.633C-82.162-782.57-186.13-837.153-311.758-847.839c-114.942-9.819-181.366 42.454-251.544 97.904C-640.701-689.576-727.918-620.553-896-620.553v2.888c169.237 0 256.743-69.023 334.142-130.249 69.6-54.872 135.447-106.856 249.523-97.325Z" />
        <path d="M-308.581-806.829c260.498 19.928 373.996 249.524 494.426 492.404C322.736-38.043 464.248 247.58 835.645 247.58c172.995 0 310.745-44.764 421.075-136.891 97.9-81.441 163.46-189.741 226.99-293.998 115.23-189.742 224.11-369.087 508.29-369.087v-2.888c-285.62 0-394.79 179.923-510.6 370.531-128.23 211.112-261.07 429.734-645.466 429.734-369.376 0-510.599-284.757-647.201-560.272C68.014-559.038-46.062-789.212-308.003-809.428c-119.563-9.241-184.543 48.808-253.278 110.322-75.665 67.002-161.15 143.533-334.719 143.533v2.888c174.724 0 260.786-76.82 336.452-144.4C-491.391-758.31-426.7-816.07-308.581-806.829Z" />
        <path d="M-557.526-646.545c67.001-67.001 130.248-130.537 252.988-122.162 242.592 16.75 356.957 247.502 478.253 491.826C245.337-132.769 319.27 16.54 423.238 129.461 541.069 257.399 682.003 319.78 854.128 319.78c175.012 0 312.482-45.341 420.782-138.335 95.88-82.308 157.97-191.474 218.04-296.886 109.17-191.475 212.27-372.552 499.34-372.552v-2.888c-288.8 0-392.19 181.655-501.65 373.996-121.58 213.134-246.92 433.777-636.223 433.777-382.66 0-532.836-302.662-677.814-595.217C55.018-523.516-59.924-754.845-303.96-771.884c-124.184-8.664-187.72 55.161-255.01 122.74-74.222 74.799-157.974 158.551-337.03 158.551v2.888c180.211 0 264.252-84.329 338.474-158.84Z" />
        <path d="M-555.505-595.427c65.558-73.355 127.65-142.379 254.722-134.581C-75.808-716.146 38.846-484.817 160.43-239.914 235.807-88.006 313.783 69.39 423.238 189.82c124.184 136.602 271.183 203.026 449.084 203.026 177.038 0 314.508-45.63 420.498-139.49 93.57-83.175 152.48-193.207 209.09-299.774 102.81-193.208 200.13-376.018 490.38-376.018v-2.888c-291.98 0-389.59 183.388-492.69 377.462-114.66 215.444-233.07 438.109-626.989 438.109-396.233 0-562.582-335.297-709.293-631.028C41.445-486.55-73.787-718.456-300.206-732.607c-128.516-7.798-191.185 61.803-257.032 135.447C-633.481-512.83-711.746-425.613-896-425.613v2.888c185.698 0 268.006-91.838 340.495-172.702Z" />
        <path d="M-553.772-544.309c64.402-79.42 125.05-154.22 256.743-147.288 94.727 4.909 175.302 54.005 252.99 153.93 69.6 89.239 128.226 207.647 190.318 333.275 155.663 314.214 331.832 670.016 743.949 670.016 179.052 0 316.522-46.208 419.912-140.934 91.55-84.041 146.71-195.229 200.14-302.663 96.75-195.228 188.01-379.483 481.43-379.483v-2.888c-295.15 0-386.7 185.121-484.03 380.927-107.72 217.467-219.2 442.153-617.452 442.153-410.096 0-585.975-355.224-741.35-668.572C86.786-331.464 27.871-450.161-41.73-539.689c-78.553-100.502-159.417-149.887-255.299-154.796-133.137-6.932-194.362 68.445-259.053 148.443C-629.726-455.07-706.258-360.633-896-360.633v2.888c191.186 0 271.472-99.058 342.228-186.564Z" />
        <path d="M-551.75-493.192c62.959-85.485 122.452-166.06 258.765-159.995 190.897 8.375 299.486 228.152 425.114 482.585 164.038 332.12 350.314 708.715 776.583 708.715 181.078 0 318.258-46.497 419.628-142.378 89.53-84.619 141.22-196.962 191.18-305.55 90.69-196.962 176.17-382.661 472.48-382.661v-2.888c-298.04 0-384.1 186.854-475.08 384.393-101.08 219.488-205.33 446.196-608.208 446.196-424.536 0-610.234-375.729-773.984-707.271C72.636-297.674 14.009-416.371-53.281-505.033c-75.377-99.347-151.62-147.288-239.704-151.331-137.757-6.065-197.828 75.088-261.364 161.439-72.2 97.904-146.999 199.272-341.939 199.272v2.888c87.795 0 159.418-21.082 219.199-64.113 52.851-38.122 89.528-88.084 125.339-136.314Z" />
        <path d="M-550.018-441.785c61.804-91.55 120.141-178.19 260.787-173.28C-114.796-609-7.651-390.956 116.822-138.545c82.885 168.37 176.745 359.267 302.951 503.667 144.4 165.194 310.46 245.48 507.133 245.48 183.094 0 320.274-47.074 419.334-143.534 87.51-85.484 135.74-198.983 182.24-308.438 84.33-198.694 164.03-386.126 463.52-386.126v-2.888c-301.22 0-381.5 188.298-466.12 387.859-94.15 221.509-191.48 450.528-598.686 450.528-439.553 0-635.071-397.1-807.484-747.415C-5.052-392.689-112.486-611.311-288.654-617.664c-142.667-4.91-201.293 82.308-263.385 174.435C-623.084-338.684-695.862-230.384-896-230.384v2.888c90.106 0 162.883-22.526 222.665-68.445 52.561-40.721 88.373-94.149 123.317-145.844Z" />
        <path d="M-548.285-390.09c60.359-97.615 117.542-190.031 263.097-186.276 158.262 3.754 263.963 219.488 386.126 469.011 86.351 176.457 184.254 376.595 316.236 529.37 151.62 175.88 324.322 261.364 527.926 261.364 185.12 0 322.3-47.363 418.76-144.977 85.49-86.351 130.25-200.716 173.28-311.327 78.27-200.427 152.2-389.591 454.57-389.591v-2.888c-304.39 0-378.62 190.03-457.46 391.324-87.22 223.531-177.61 454.571-589.439 454.571-455.437 0-669.727-437.532-841.852-789.29-122.74-250.39-228.441-466.701-388.725-470.455-147.288-3.755-204.759 89.239-265.696 187.72-68.156 111.188-139.201 226.13-344.538 226.13v2.888c92.705 0 166.638-23.682 226.13-72.489 52.562-43.32 87.507-100.213 121.585-155.085Z" />
        <path d="M-546.552-338.395c58.626-102.813 114.076-199.85 260.786-199.85h4.332c142.668 1.733 246.058 214.001 365.332 459.77 180.789 371.686 406.053 834.632 879.397 834.632 187.435 0 324.035-47.94 418.475-146.421 83.46-87.218 124.47-202.738 164.32-314.215C1618 93.361 1686.16-97.536 1991.71-97.536v-2.888c-307.57 0-376.02 191.764-448.51 394.79-80.28 225.553-163.46 458.614-580.194 458.614-471.611 0-696.297-461.791-876.797-832.899C26.427-203.237-30.177-319.334-88.804-403.086c-65.269-93.283-126.494-137.18-192.918-138.047-151.909-2.021-208.225 96.748-267.718 201.294C-616.153-222.008-685.465-100.424-896-100.424v2.888c95.015 0 170.103-25.125 229.596-76.821 52.273-45.63 86.64-105.7 119.852-164.038Z" />
        <path d="M-297.318 203.105c138.624 8.664 257.61 145.266 395.656 303.24 86.352 99.058 184.544 211.69 299.775 304.973 132.27 107.145 267.14 173.569 412.984 203.022 58.337 11.85 114.653 17.62 171.836 17.62h9.241c378.906-4.91 466.986-160.861 552.186-311.325 78.26-138.336 152.2-268.873 447.64-268.873v-2.888c-297.18 0-371.4 131.404-449.95 270.317-84.91 149.887-172.7 304.969-549.876 309.879-60.07.58-119.274-5.2-180.5-17.61C485.33 945.899 280.86 711.393 100.36 504.612-37.975 346.061-157.25 209.17-297.318 200.506c-127.072-7.798-194.94 49.962-266.851 110.899C-643.3 378.984-725.319 448.874-896 448.874v2.888c171.836 0 254.144-70.179 334.142-138.336 71.333-60.648 138.624-118.119 264.54-110.321Z" />
        <path d="M-294.718 115.31c137.468 7.509 254.432 153.641 389.59 322.878C180.359 545.044 277.107 666.34 392.049 767.42c131.693 116.098 267.429 188.009 414.428 220.066 58.915 12.994 118.408 19.354 177.034 19.354h7.22c384.68-4.34 471.32-170.108 555.07-330.392 76.54-146.421 149.02-285.045 446.2-285.045v-2.888c-298.91 0-371.69 139.201-448.8 286.489-83.46 159.707-169.81 324.611-552.758 328.946-60.648.58-122.452-6.067-183.677-19.352-328.943-71.623-540.345-335.875-709.87-548.143-135.737-169.814-252.989-316.524-391.613-324.033-130.827-7.22-196.673 56.605-266.852 123.895-77.398 74.51-157.973 151.909-334.43 151.909v2.888c177.612 0 258.476-77.687 336.741-152.775 69.312-67.002 135.158-129.96 264.541-123.029Z" />
        <path d="M-556.949 163.251C-489.081 90.184-425.256 21.45-292.408 27.515-156.094 33.868-41.73 189.243 91.118 369.454c84.33 114.653 180.211 244.613 294.576 353.491 131.693 125.05 267.718 202.449 416.161 237.105 60.071 14.151 121.007 21.371 181.655 21.371h6.065c390.165-3.755 475.365-179.634 557.965-349.448 75.08-154.797 146.13-300.929 444.75-300.929v-2.888c-300.35 0-371.69 146.999-447.35 302.662-82.02 169.237-166.93 344.249-555.365 348.004-62.092.577-125.05-6.643-186.853-21.083-332.12-77.687-541.212-361.288-709.004-589.44C-39.42 187.51-154.362 31.558-291.83 25.204c-134.292-6.353-198.695 63.247-266.852 136.603-76.243 81.152-155.085 166.06-337.318 166.06v2.888c183.677 0 262.519-85.196 339.051-167.504Z" />
        <path d="M-554.638 88.162c66.135-79.13 128.227-153.93 264.829-148.443 134.87 5.199 247.213 169.815 377.173 360.711 83.463 122.452 177.901 261.364 292.266 377.751 131.404 134.003 268.006 217.177 417.893 254.721 60.937 15.307 123.607 23.104 185.987 23.104h4.91c177.03-1.444 307.28-40.143 398.54-118.696 80.58-69.601 122.17-161.151 162.02-249.812 73.36-163.461 142.67-317.392 443.02-317.392v-2.888c-302.08 0-371.97 154.797-445.91 318.836-80.57 178.767-164.04 363.888-557.959 367.064-63.825.578-127.649-7.22-190.03-23.104-335.586-83.752-542.367-387.28-708.427-631.316C-40.863 107.223-153.206-57.97-289.52-63.169-427.278-68.367-490.236 6.721-556.66 86.43-631.17 175.09-707.991 267.218-896 267.218v2.888c189.453 0 266.562-92.416 341.362-181.944Z" />
        <path d="M-552.617 13.075c64.403-85.197 125.051-165.772 265.119-161.44C-153.784-144.322-44.04 29.536 83.321 230.83 165.629 361.079 259.2 508.944 373.276 633.417c131.115 142.956 268.295 232.195 419.915 272.338 62.092 16.462 125.917 24.837 190.319 24.837h3.755c179.635-1.155 311.035-41.876 402.295-124.473 80.87-73.066 121.59-169.814 160.86-263.385 71.92-171.259 140.07-332.987 441.87-332.987v-2.888c-303.82 0-372.26 162.595-444.75 334.719-79.42 188.587-161.15 383.527-560.564 386.126a738.797 738.797 0 0 1-193.207-24.837c-339.052-90.105-543.522-413.561-707.85-673.481C-41.73 27.515-152.05-146.921-286.92-150.964c-141.512-4.332-202.737 76.82-267.429 162.595C-627.416 107.512-702.504 206.57-896 206.57v2.888c194.94 0 270.606-99.636 343.383-196.383Z" />
        <path d="M-550.595-61.725c61.514-89.528 119.852-174.435 257.609-174.435h7.798C-153.206-233.272-45.773-50.461 78.7 160.94c162.883 276.671 365.621 620.92 709.293 717.379 62.958 17.617 128.227 26.57 194.651 26.57h2.599c181.947-.867 314.797-43.32 406.337-130.249 80.87-76.821 120.72-178.478 159.42-276.67 70.47-179.345 136.89-348.871 440.71-348.871v-2.888c-305.55 0-372.55 170.392-443.31 350.604-77.97 198.405-158.26 403.164-563.157 405.186-67.001.289-132.848-8.664-196.384-26.57-342.516-96.17-544.965-439.842-707.56-716.224C18.63 52.929-40.286-47.285-99.2-119.485c-65.557-80.286-124.473-118.408-185.987-119.563h-8.086c-139.491 0-198.117 85.485-260.209 175.59C-623.662 39.644-696.728 146.212-896 146.212v2.888c200.716 0 274.36-107.144 345.405-210.824Z" />
        <path d="M-548.574-136.813c60.36-96.459 117.542-187.431 260.787-187.431h5.198c130.538 2.022 235.661 193.207 357.246 414.428C236.096 383.605 436.523 748.071 783.95 850.883c63.825 19.061 130.537 28.592 198.694 28.592h1.733c184.543-.578 318.543-45.053 410.093-136.025 81.16-80.575 120.14-187.143 157.98-290.244 69.02-187.72 134-364.755 439.26-364.755v-2.888c-307.28 0-372.84 178.19-442.15 366.776-76.53 207.936-155.38 423.092-565.761 424.248h-1.732c-67.868 0-134.292-9.531-197.828-28.303C438.545 745.76 238.407 381.583 77.545 89.029 16.319-22.448-41.441-127.571-99.201-202.948-163.314-286.7-221.652-326.265-282.3-327.132h-5.198c-144.978 0-202.449 91.839-263.386 188.875C-619.907-28.224-691.241 85.563-896 85.563v2.888c206.492 0 278.114-114.364 347.426-225.264Z" />
        <path d="M-546.552-211.612c59.493-103.679 115.809-201.582 266.562-200.427 59.204.578 115.52 41.01 177.901 127.361C-45.484-206.413 10.832-96.67 70.614 19.717 230.03 329.31 428.437 714.57 779.329 823.736c64.691 20.216 132.848 30.324 202.737 30.324h.867c186.857-.289 322.297-46.785 414.137-141.801 81.15-84.329 119.57-195.806 156.82-303.528C1621.18 212.924 1685.01 28.092 1992 28.092v-2.888c-309.02 0-373.13 185.699-440.71 382.66-75.09 217.755-152.49 443.019-568.068 443.597h-.867c-69.601 0-137.757-10.108-201.871-30.324C430.747 712.259 232.63 327.578 73.79 18.562 14.01-97.825-42.596-207.57-99.2-286.122c-62.959-87.218-120.142-127.939-180.212-128.516h-2.599c-150.754 0-207.07 98.192-266.563 201.871C-616.153-95.803-685.754 25.204-896 25.204v2.888c211.979 0 281.869-121.873 349.448-239.704Z" />
        <path d="M-544.53-282.379c58.048-107.846 112.632-209.732 267.14-209.732 127.649 0 223.242 194.406 343.96 440.75 93.283 190.149 199.272 405.557 343.384 574.421C576.88 718.601 763.733 813.392 981.777 813.392c189.453 0 326.053-47.395 417.893-145.308 81.44-86.561 118.99-200.934 155.38-311.618C1620.89 156.1 1683.27-33.198 1992-33.198v-2.838c-310.75 0-373.42 190.149-439.55 391.367-73.65 223.638-149.6 454.939-570.673 454.939-488.649 0-723.732-478.495-912.607-863.05C10.543-171.98-44.618-284.65-100.067-364.684c-61.515-89.115-117.831-130.55-177.323-130.55-156.53 0-211.402 102.737-269.74 211.435C-612.398-162.046-679.978-36.036-896-36.036v2.838c217.755 0 285.912-126.861 351.47-249.181Z" />
      </g>
      <path
        d="M1035.68-50c-43.504 36.5-98.304 130.5 30.5 214.5 128.8 84 246-56 288.5-136.5"
        stroke="url(#b)"
        strokeWidth="8"
        strokeLinecap="round"
      />
      <path
        d="M505.789 212.09c-28.273-26.186-68.477-95.751-3.104-164.516 61.932-65.146 138.266-44.85 116.778-180.479-26.18-165.244 157.162-167.119 228.085-191.921"
        stroke="url(#c)"
        strokeWidth="8"
        strokeLinecap="round"
      />
      <defs>
        <linearGradient
          id="b"
          x1="1170.34"
          y1="-50"
          x2="1170.34"
          y2="190.437"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset="1" stopColor="#fff" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="c"
          x1="686.264"
          y1="-343.996"
          x2="618.576"
          y2="225.496"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".453" stopColor="#fff" />
          <stop offset="1" stopColor="#fff" stopOpacity="0" />
        </linearGradient>
        <clipPath id="a">
          <path
            fill="#fff"
            transform="translate(-896 -1478)"
            d="M0 0h2888v2888H0z"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
