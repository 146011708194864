import { useState } from 'react';
import { Richtext } from '@storyofams/storyblok-toolkit';
import { getCookies } from 'cookies-next';
import { useRouter } from 'next/router';
import { useForm } from 'react-hook-form';

import { classNames, track } from '~/lib';
import { Button } from '../Button';
import { Heading } from '../Heading';
import { RichText } from '../Molecules';

type NewsletterSimpleProps = {
  content?: {
    title?: string;
    number?: number;
    text?: Richtext;
    button?: string;
  };
};

export const NewsletterSimple = ({ content }: NewsletterSimpleProps) => {
  const { asPath } = useRouter();
  const pageName =
    asPath?.split('?')[0] === '/'
      ? 'Home'
      : asPath?.split('?')[0]?.split('/')[1];

  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm();

  const onSubmit = async (input) => {
    setError('');
    setSuccess('');

    try {
      const cookies = getCookies();
      const hubspotutk = cookies?.hubspotutk || '';

      const res = await fetch(`/api/newsletter`, {
        method: 'POST',
        body: JSON.stringify({
          ...input,
          pageName,
          hutk: hubspotutk,
        }),
        headers: { 'Content-Type': 'application/json' },
      });

      if (res.status !== 200) {
        setError((await res?.json())?.message);
      } else {
        setSuccess('Subscribed successfully!');
        track({
          event: 'new_subscriber',
        });
      }
    } catch (e) {
      setError(e?.message);
    }
  };

  return (
    <div className="w-full bg-yellow-50 rounded-2xl px-6 py-8 md:p-10">
      <div className="flex items-center">
        <Heading as="h4" variant="h4">
          {content?.title}
        </Heading>
        {content?.number && (
          <div className="bg-yellow-300 rounded-full w-7 h-7 flex items-center justify-center text-sm text-yellow-800 font-medium ml-3">
            {content.number}
          </div>
        )}
      </div>
      <div className="w-full flex flex-col lg:flex-row lg:justify-between lg:items-end mt-5">
        {content?.text && (
          <RichText
            text={content?.text}
            className="w-full lg:max-w-sm !text-base"
          />
        )}

        <form
          onSubmit={handleSubmit(onSubmit)}
          className="w-full lg:max-w-[482px] flex flex-col md:flex-row mt-5 lg:mt-0"
        >
          <input
            className="absolute"
            aria-hidden="true"
            type="text"
            tabIndex={-1}
            style={{ left: '-5000px' }}
            {...register('b9127913821798nd1ee821u9ejo')}
          />
          <div className="w-full drop-shadow-light border border-brand rounded">
            <label htmlFor="cta-email" className="sr-only">
              Email address
            </label>
            <input
              id="cta-email"
              type="email"
              className={classNames(
                !!success && 'bg-gray-50 text-gray-700',
                'block w-full rounded border-none px-5 py-3 text-base text-gray-900 font-normal placeholder-grey-300 focus:outline-none focus:border-transparent focus:ring-0 focus:ring-yellow-300',
              )}
              placeholder="Enter your work email"
              required
              {...register('email', { required: true })}
              defaultValue=""
              disabled={!!success}
            />
          </div>
          <Button
            type="submit"
            variant="primary"
            size="medium"
            className="w-full md:max-w-[124px] border-none px-[14px] lg:px-8 md:ml-3 mt-2 md:mt-0"
            isLoading={isSubmitting}
            disabled={!!success}
          >
            {success ? 'Subscribed!' : content?.button || 'Notify me'}
          </Button>
        </form>
      </div>
    </div>
  );
};
