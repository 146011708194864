import { smallFeatureIcons } from '../Blocks/FeatureSection';
import { Button } from '../Button';
import { Heading } from '../Heading';
import { NewsletterBackground } from './NewsletterBanner';

interface FeatureBannerProps {
  content?: any;
}

export const FeatureBanner = ({ content }: FeatureBannerProps) => {
  return (
    <div className="bg-yellow-50 border border-yellow-300 rounded-2xl py-10 px-4 md:p-20 relative overflow-hidden">
      <div className="relative z-20 flex flex-col lg:flex-row lg:justify-between">
        <div className="text-center lg:text-left max-w-md mx-auto lg:max-w-sm lg:mx-0">
          <Heading
            variant="h3"
            as="h3"
            className="!text-3xl lg:!text-6xl font-bold leading-[110%]"
          >
            {content?.title}
          </Heading>

          <div className="w-full flex flex-col mt-6 lg:mt-14">
            {!!content?.button && (
              <Button variant="white" href={content?.button_link}>
                {content.button}
              </Button>
            )}

            {!!content?.button_2 && (
              <Button
                variant="primary"
                href={content?.button_2_link}
                className="mt-2"
              >
                {content?.button_2}
              </Button>
            )}
          </div>
        </div>

        {!!content?.features && (
          <ul className="bg-white rounded-t-2xl shadow-dark-xl py-6 px-5 lg:p-8 max-w-md w-full -mb-10 md:-mb-20 mt-12 lg:mt-0 mx-auto lg:mx-0">
            {content.features.map((feat) => {
              const Icon = smallFeatureIcons.find((i) => i.value === feat?.icon)
                ?.icon;

              return (
                <li
                  key={feat?._uid}
                  className="py-6 first:pt-0 last:pb-0 border-b last:border-b-0 lg:text-lg flex"
                >
                  {!!Icon && <Icon className="text-success-500 w-4 mr-4" />}
                  <div>{feat?.text}</div>
                </li>
              );
            })}
          </ul>
        )}
      </div>
      <NewsletterBackground className="absolute top-0 right-0 bottom-0 left-0 z-0" />
    </div>
  );
};
