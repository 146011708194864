import {
  Root as DialogRoot,
  Trigger,
  Portal,
  Overlay,
  Content,
  Title,
  Description,
} from '@radix-ui/react-dialog';

import { classNames, getLinkProps } from '~/lib';
import { smallFeatureIcons } from '../Blocks/FeatureSection';
import { Button } from '../Button';
import { Form } from '../Forms';
import { Heading } from '../Heading';
import { BannerBgWave } from '../Vectors/BannerBgWave';

interface CtaBannerProps {
  content?: any;
  className?: string;
}

export const CtaBanner = ({ content, className }: CtaBannerProps) => {
  const ButtonIcon = smallFeatureIcons.find(
    (i) => i.value === content?.button_2_icon,
  )?.icon;

  return (
    <div
      className={classNames(
        'w-full bg-dark-grey-700 rounded-3xl py-12 px-6 md:px-8 md:p-16 text-white relative overflow-hidden z-10 flex flex-col items-center',
        className,
      )}
    >
      <div className="absolute inset-0 w-full h-full z-0">
        <BannerBgWave className="h-full lg:h-auto" />
      </div>
      <div className="sm:max-w-3xl text-center lg:text-left flex flex-col items-center relative">
        {!!content?.title && (
          <Heading
            as="h4"
            variant="h2"
            className="text-white text-center leading-[1.1] font-bold"
          >
            {content?.title}
          </Heading>
        )}
        {!!content?.subtext && (
          <div className="text-center mt-3 sm:max-w-sm">{content?.subtext}</div>
        )}

        <DialogRoot>
          <div className="mt-10 flex flex-col md:flex-row justify-center w-full">
            {!!content?.button && content?.button_action === 'modal' ? (
              <Trigger asChild>
                <Button
                  variant="primary"
                  size="large"
                  className="shadow-yellow w-full md:max-w-max"
                >
                  {content?.button}
                </Button>
              </Trigger>
            ) : (
              !!content?.button && (
                <Button
                  variant="primary"
                  size="large"
                  className="shadow-yellow w-full md:max-w-max"
                  href={getLinkProps(content?.button_link)}
                >
                  {content?.button}
                </Button>
              )
            )}
            {!!content?.button_2 && content?.button_2_action === 'modal' ? (
              <Trigger asChild>
                <Button
                  variant="primary"
                  size="large"
                  className="shadow-yellow w-full md:max-w-max"
                >
                  {content?.button_2}
                </Button>
              </Trigger>
            ) : (
              !!content?.button_2 && (
                <Button
                  variant="white"
                  size="large"
                  className="md:ml-5 border-0 w-full md:max-w-max mt-4 md:mt-0 flex items-center justify-center"
                  href={getLinkProps(content?.button_2_link)}
                >
                  {!!ButtonIcon && <ButtonIcon className="h-2.5 mr-2" />}
                  {content?.button_2}
                </Button>
              )
            )}
          </div>

          <Portal>
            <Overlay className="fixed inset-0 overflow-y-auto z-50 bg-black/30">
              <div className="flex min-h-full items-center justify-center p-4">
                <Content className="mx-auto w-full max-w-sm md:max-w-lg bg-white px-5 py-6 sm:p-8 rounded-2xl relative">
                  <Title className="text-2xl font-medium leading-tight">
                    {content?.form_title}
                  </Title>
                  <Description className="text-sm md:text-base text-gray-700 mt-3 md:mt-5">
                    {content?.form_subtitle}
                  </Description>

                  <Form className="mt-6" content={content} />
                </Content>
              </div>
            </Overlay>
          </Portal>
        </DialogRoot>
      </div>
    </div>
  );
};
