import { BannerSimple } from './BannerSimple';
import { CtaBanner } from './CtaBanner';
import { FeatureBanner } from './FeatureBanner';
import { GuideBanner } from './GuideBanner';
import { NewsletterBanner } from './NewsletterBanner';
import { NewsletterSimple } from './NewsletterSimple';

interface BannerDynamicProps {
  content: any;
}

export const BannerDynamic = ({ content }: BannerDynamicProps) => {
  let banner = null;

  switch (content?.component) {
    case 'banner_simple':
      banner = <BannerSimple content={content} />;
      break;
    case 'banner':
      banner = <CtaBanner content={content} />;
      break;
    case 'newsletter':
      banner = <NewsletterBanner content={content} />;
      break;
    case 'newsletter_banner_simple':
      banner = <NewsletterSimple content={content} />;
      break;
    case 'banner_guide':
      banner = <GuideBanner content={content} />;
      break;
    case 'feature_banner':
      banner = <FeatureBanner content={content} />;
      break;
  }

  return banner;
};
